import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom"
import Api from '../components/Constant/Api';
import Loader from 'react-loader-spinner';
import "./View.css"


export default class Questions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      selectedCategory: "",
      selectedSubCategory: "",
      validationError: "",
      subcategory: "",
      subCat: [],
      selectedTest: "",
      data: [],
      errors: {},
      fields: {},
      file: "",
      loader:''
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    this.setState({ errors: errors });
    return formIsValid;
  }


  // componentDidMount() {
  //   axios.get(Api.API_URL + 'list-category')
  //     .then(res => {
  //       if (res.data.status === "200") {
  //         // console.log("result array: ", res.data.result);
  //         let categoriesFromApi = res.data.result.map(cat => {
  //           return { value: cat.categoryId, display: cat.category };
  //         });
  //         this.setState({
  //           categories: [
  //             {
  //               value: "",
  //               display: "Select Category"
  //             }
  //           ].concat(categoriesFromApi)
  //         });
  //       }
  //       else {
  //         toast.error(res.data.message);
  //       }
  //     })
  //     .catch(error => {
  //       toast.error(error);
  //     });
  // }

  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    // console.log("setcategory",e.target.value)
    this.fetchSubcategory(e.target.value);
  }


  fetchSubcategory(selectedCategory) {
    // console.log("fetchsub: ", selectedCategory)
    if (selectedCategory !== '') {
      var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
      axios.get(url)
        .then(res => {
          if (res.data.status === "200") {
            // console.log("result array: ", res.data.result);
            let subCategoriesFromApi = res.data.result.map(cat => {
              return { value: cat.subcategoryId, display: cat.subcatName };
            });
            console.log("subcategories from API: ", subCategoriesFromApi);
            this.setState({
              subCat: [
                {
                  value: "",
                  display: "Select Sub-Category"
                }
              ].concat(subCategoriesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }


  setSubCategory = (e) => {
    this.setState({
      selectedSubCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite sub category"
          : ""
    })
    // console.log("set sub category:",e.target.value)
    this.ShowTest(e.target.value)
  }


  ShowTest(selectedSubCategory) {
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10
    }
    if (selectedSubCategory !== '') {
      var url = Api.API_URL + 'subcategory-wise-test?subcategoryId=' + selectedSubCategory;
      axios.post(url, pagination)
        .then(res => {
          if (res.data.status === "200") {
            let testformapi = res.data.result.map(test => {
              return { value: test.testId, display: test.testName };
            });
            this.setState({
              data: [
                {
                  value: "",
                  display: "Select Test Id"
                }
              ].concat(testformapi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }

  setTestId = (e) => {
    this.setState({
      selectedTest: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must Test Id"
          : ""
    })
  }

  setQuestionParameter = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  addQuestion = () => {
    this.setState({loader:true})
    const formData = new FormData();
    formData.append('testId', this.state.selectedTest);
    formData.append('file', this.state.file);
    console.log("formdata", formData);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    axios.post(Api.API_URL + "question/import", formData, config)
      .then(res => {
        // if (res.data.status === "200") {
        //   this.setState({loader:false})
        //   toast.success(res.data.message)
        // }
        // if else {
        //   this.setState({loader:false})

        //   toast.error(res.data.message)
        // }
        //  if else {
        //   this.setState({loader:false})

        // }
        if (res.data.status === "200") {
          this.setState({loader:false})
          toast.success(res.data.message)
        } else if (this.data.status === "400") {
          this.setState({loader:false})

        } else {
          this.setState({loader:false})

          toast.error(res.data.message)
        }
      })


  }

  setCorrectOption = (event) => {
    this.setState({
      setCorrectOption: event.target.value
    });
    console.log("Test Type: ", event.target.value);
    // this.addquestion()
  }

  importexcel = (e) => {
    // console.log("value of e:", e.target.files);
    this.setState({ file: e.target.files[0] })
  }
  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api.API_URL +'module-wise-list-category',pagination)
    .then(response => {
  
      this.setState({categories: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })
  }
  render() {
    if(this.state.loader ===true){
      return <div className="text-center  marginloader" ><Loader
      type="Puff"
      color="#00e600"
      height={100}
      width={100}
      
     //  timeout={3000} //3 secs

   /></div> 
    }
    return (
      <React.Fragment>
  <h5 className="text-muted pl-5 pt-4 text-right">
                <Link to="/showquestions" className="dropdown-item"><span className="material-icons" >add</span>All Questions</Link>
              </h5>
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 pb-5 mb-5">
              <div className="card">
                <div className="card-body">
                  <h5 className="text-muted pl-2 pt-2">Add Questions</h5><br></br>
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                  <label className="text-muted">Select Category</label>
                  <select
                    value={this.state.selectedCategory}
                    onChange={this.setCategory}
                    className="form-control">
                    {this.state.categories.map(cat => (
                      <option
                        key={cat.categoryId}
                        value={cat.categoryId}
                      >
                        {cat.category}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["category"]}</span>
                  <br></br>

                  <label className="text-muted">Select Sub-category</label>
                  <select
                    value={this.state.selectedSubCategory}
                    onChange={this.setSubCategory}
                    className="form-control">
                    {this.state.subCat.map(cat => (
                      <option
                        key={cat.value}
                        value={cat.value}
                      >
                        {cat.display}

                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["subcategory"]}</span>
                  <br></br>

                  <label className="text-muted">Select Test</label>
                  <select
                    value={this.state.selectedTest}
                    onChange={this.setTestId}
                    className="form-control">
                    {this.state.data.map(test => (
                      <option
                        key={test.value}
                        value={test.value}
                      >
                        {test.display}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["testId"]}</span><br></br>

                  <label htmlFor="exampleFormControlFile1">Import Excel File</label>
                  <input type="file" name="file" className="form-control-file" id="exampleFormControlFile1" onChange={this.importexcel} /><br></br><hr></hr>

                  <button type="button" className="btn primary  ml-3 " onClick={this.addQuestion}> Add questions </button>
                </div>
              </div>
            </div>

          </div>
        </div>


        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </React.Fragment>
    )
  }
}
