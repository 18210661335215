import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

export default class AddOneLiner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            selectedCategory: "",
            selectedSubCategory: "",
            validationError: "",
            subcategory: "",
            subCat: [],
            addquestion: "",
            addanswer: "",
            questionError:"",
            answareError:""
        }
    }

    componentDidMount() {
        axios.get('https://agriguru.avertastrategy.com/v1/list-category')
            .then(res => {
                if (res.data.status === "200") {
                    // console.log("result array: ", res.data.result);
                    let categoriesFromApi = res.data.result.map(cat => {
                        return { value: cat.categoryId, display: cat.category };
                    });
                    this.setState({
                        categories: [
                            {
                                value: "",
                                display: "Select Category"
                            }
                        ].concat(categoriesFromApi)
                    });
                }
                else {
                    toast.error(res.data.message);
                }
            })
            .catch(error => {
                toast.error(error);
            });
    }

    setCategory = (e) => {
        this.setState({
            selectedCategory: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must select your favourite category"
                    : ""
        })
        // console.log("setcategory",e.target.value)
        this.fetchSubcategory(e.target.value);
    }


    fetchSubcategory(selectedCategory) {
        // console.log("fetchsub: ", selectedCategory)
        if (selectedCategory !== '') {
            var url = 'https://agriguru.avertastrategy.com/v1/category-wise-subcategory/' + selectedCategory;
            axios.get(url)
                .then(res => {
                    if (res.data.status === "200") {
                        // console.log("result array: ", res.data.result);
                        let subCategoriesFromApi = res.data.result.map(cat => {
                            return { value: cat.subcategoryId, display: cat.subcatName };
                        });
                        console.log("subcategories from API: ", subCategoriesFromApi);
                        this.setState({
                            subCat: [
                                {
                                    value: "",
                                    display: "Select Sub-Category"
                                }
                            ].concat(subCategoriesFromApi)
                        });
                    }
                    else {
                        toast.error(res.data.message);
                    }
                })
                .catch(error => {
                    toast.error(error);
                });
        }
    }


    setSubCategory = (e) => {
        this.setState({
            selectedSubCategory: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must select your favourite sub category"
                    : ""
        })
        // console.log("set sub category:",e.target.value)
    }

    setOneliner = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        // console.log("setOneliner",e.target.value)
    }

    oneLiner = () => {
        
        var passdata = {
            "categoryId": this.state.selectedCategory,
            "subcategoryId": this.state.selectedSubCategory,
            "answer": this.state.addanswer,
            "question": this.state.addquestion

        }
        console.log("passdata", passdata)
        
        axios.post('https://agriguru.avertastrategy.com/v1/add-one-liners', passdata)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error(res.data.message)
                }
            })
            
        }
        
        
   

    render() {
        return (
            <React.Fragment>
                <h5 className="text-muted pl-5 pt-4">Add OneLiner</h5>
                <div className="form-group">
                    <div className="container pt-3">
                        <div className="row">
                            <div className="col-md-6">
                                <select
                                    value={this.state.selectedCategory}
                                    onChange={this.setCategory}
                                    className="form-control">
                                    {this.state.categories.map(cat => (
                                        <option
                                            key={cat.value}
                                            value={cat.value}
                                        >
                                            {cat.display}
                                        </option>
                                    ))}
                                </select><br></br>
                            </div>
                            <div className="col-md-6">
                                <select
                                    value={this.state.selectedSubCategory}
                                    onChange={this.setSubCategory}
                                    className="form-control">
                                    {this.state.subCat.map(cat => (
                                        <option
                                            key={cat.value}
                                            value={cat.value}
                                        >
                                            {cat.display}

                                        </option>
                                    ))}
                                </select><br></br>
                            </div>
                            <div className="col-md-6">
                                <label>Question</label>
                                <input type="text" className="form-control" name="addquestion" onChange={this.setOneliner}></input><br></br>
                                    <p>{this.state.questionError}</p>
                                <button className="btn primary" type="button" onClick={this.oneLiner}>Add OneLiner</button>
                            </div>
                            <div className="col-md-6">
                                <label>Answare</label>
                                <input type="text" className="form-control" name="addanswer" onChange={this.setOneliner}></input><br></br>
                            </div><br></br>

                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        )
    }
}
