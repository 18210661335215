import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';
import Api from '../Constant/Api'
import {Link} from "react-router-dom";
import { Player } from 'video-react';
import "./video.css";


export default class ListVideos extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderBy: '',
      orderby: [{ "value": " ", "display": "select video type" }, { "value": "online-videos", "display": "online" }, { "value": "offline-videos", "display": "offline" },],
      columns: [
        { title: <h5><b>title</b></h5>, field: 'title', initialEditValue: '' },
        { title: <h5><b>description</b></h5>, field: 'description', initialEditValue: '' },
        { title: <h5><b>Price</b></h5>, field: 'price', initialEditValue: '' },
        { title: <h5><b>videoType</b></h5>, field: 'videoType', initialEditValue: '' },
        { title: <h5><b>videoUrl</b></h5>, field: 'videoUrl', initialEditValue: '' },
        { title: <h5><b>Thumbnail Image</b></h5>, field: 'imgUrl', initialEditValue: '' },
      ],
      data: [],
      status: '',
      categories: [],
      selectedCategory: "",
      selectedSubCategory: "",
      subCat: [],


    }
  }

componentDidMount(){
  this.listCategory()
}
  orderbyvalue = (e) => {
    this.setState({
      status: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select order by"
          : ""
    })
    
     this.fetchListVideoforpagi(e.target.value);
  }

  fetchListVideo = (selectedSubCategory) => {
this.setState({selectedSubCategory:selectedSubCategory})
    var pagination =
    {
      "pageNum": 1,
      "numPerPage": 10,
    }
      var url = Api.API_URL+'subcategory-wise-video?subcategoryId='+selectedSubCategory;
      console.log("URL: ", url);
      axios.post(url, pagination)
        .then(res => {
          if (res.data.status === "200") {
            const data = res.data.result;
            for(var i=0; i<res.data.result.length; i++){
              var imgUrl= <img style={{height:'100px',width:'100px'}} src={Api.IMG_PATH+res.data.result[i].thumbnailImage+"/"} />
              res.data.result[i].imgUrl=imgUrl;
          }
          this.setState({data})
            let selectedDocs = [];
          // res.data.result.map(doc => {
          //   if (doc.videoType.includes(selectedOrderbyValue)) {

          //     selectedDocs = selectedDocs.concat(doc);
          //   }

          // })
        // this.setState({
        //       data: selectedDocs
        //     })
            let notesFromApi = res.data.result.map(doc => {
              return { value: doc.notesId, display: doc.document };

            });
            this.setState({
              notes: [
                {
                  value: "",
                  display: "Select Video"
                }
              ].concat(notesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
  }

  fetchListVideoforpagi = (selectedOrderbyValue) => {
    // console.log("state values: ", this.state.status);
    var pagination =
    {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":selectedOrderbyValue
    }
      var url = Api.API_URL+'subcategory-wise-video?subcategoryId='+this.state.selectedSubCategory;
      console.log("URL: ", url);
      axios.post(url, pagination)
        .then(res => {
          if (res.data.status === "200") {
            const data = res.data.result;
            for(var i=0; i<res.data.result.length; i++){
              var imgUrl= <img style={{height:'100px',width:'100px'}} src={Api.IMG_PATH+res.data.result[i].thumbnailImage+"/"} />
              res.data.result[i].imgUrl=imgUrl;
          }
        //   for(var i=0; i<res.data.result.length; i++){
        //     var videoUrlplayer=<div className="vedosize"> <Player height='100px'width='100px'  src={Api.VIDEO_PATH+res.data.result[i].video+"/"} /></div>
        //     res.data.result[i].videoUrlplayer=videoUrlplayer;
        // }
            this.setState({data})
            let selectedDocs = [];
          res.data.result.map(doc => {
            if (doc.videoType.includes(selectedOrderbyValue)) {

              selectedDocs = selectedDocs.concat(doc);
            }

          })
        // this.setState({
        //       data: selectedDocs
        //     })
            let notesFromApi = res.data.result.map(doc => {
              return { value: doc.notesId, display: doc.document };

            });
            this.setState({
              notes: [
                {
                  value: "",
                  display: "Select Video"
                }
              ].concat(notesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
  }


  deleteVideo = (oldData) => {
    axios.delete(Api.API_URL + 'delete-video/' + oldData.videoId)
        .then(res => {
            if (res.data.status === "200") {
                toast.success(res.data.message)
            }
            else {
                toast.error(res.data.message)
            }

        })
        .catch(error => {
            toast.error(error)
        })

}
listCategory = () => {
  axios.get(Api.API_URL + 'list-category')
    .then(res => {
      if (res.data.status === "200") {
        let categoriesFromApi = res.data.result.map(cat => {
          return { value: cat.categoryId, display: cat.category };
        });
        this.setState({
          categories: [
            {
              value: "",
              display: "Select Category"
            }
          ].concat(categoriesFromApi)
        });
      }
      else {
        toast.error(res.data.message);
      }
    })
    .catch(error => {
      toast.error(error);
    });
}

setCategory = (e) => {
  this.setState({
    selectedCategory: e.target.value,
    validationError:
      e.target.value === ""
        ? "You must select your favourite category"
        : ""
  })
  this.fetchSubcategory(e.target.value);
}


fetchSubcategory(selectedCategory) {
  if (selectedCategory !== '') {
    var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
    axios.get(url)
      .then(res => {
        if (res.data.status === "200") {
          let subCategoriesFromApi = res.data.result.map(cat => {
            return { value: cat.subcategoryId, display: cat.subcatName };
          });
          this.setState({
            subCat: [
              {
                value: "",
                display: "Select Sub-Category"
              }
            ].concat(subCategoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }
}


setSubCategory = (e) => {
  this.setState({
    selectedSubCategory: e.target.value,
    validationError:
      e.target.value === ""
        ? "You must select your favourite sub category"
        : ""
  })
  this.fetchListVideo(e.target.value);
}
  render() {
    return (
      <React.Fragment>
      <div className="row mr-2">
        <div className="col-6"></div>

        <div className="col-3">  <h5 className="text-muted pl-5 pt-4 text-right">
          <Link to="/videoseries" className="dropdown-item"><span className="material-icons" >add</span>Add Videos Series</Link>
        </h5></div>
        <div className="col-3">      <h5 className="text-muted pl-5 pt-4 text-right">
          <Link to="/addvideo" className="dropdown-item"><span className="material-icons" >add</span>Add Videos</Link>
        </h5></div>

      </div>
  
        <div className="container-fluid pt-3 pb-5 mb-3">
          <div className="row">
            <div className="col-md-12 pb-3">
              <div className="card">
                <div className="card-body">
                <label>Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}
                        </option>
                      ))}
                    </select><br></br>

                    <label>Select Sub-category</label>
                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select><br></br>
                <h5 className="text-muted ">List Videos</h5><br></br>
                <select
                value={this.state.status}
                onChange={this.orderbyvalue}
                className="form-control">
                {this.state.orderby.map(docType => (
                  <option
                    key={docType.value}
                    value={docType.value}
                  >
                    {docType.display}

                  </option>
                ))}
              </select>
              <br></br>
                </div>
              </div>
            </div>


            <div className="col-md-12 pb-5 mb-5">
              {/* {this.state.isShowTable &&  */}
              <MaterialTable
                title="List videos"
                columns={this.state.columns}
                data={this.state.data}
                editable={{
                  // onRowUpdate: (newData, oldData) =>
                  //   new Promise((resolve) => {
                  //     // console.log("Resolve: ", resolve);
                  //     setTimeout(() => {
                  //       resolve();
                  //       if (oldData) {
                  //         // console.log("oldData: ", oldData);
                  //         // console.log("newData: ", newData);
                  //         { this.updateDocument(newData) }
                  //         console.log("show update", this.data)
                  //         this.setState((prevState) => {
                  //           // console.log("prevState: ", prevState);
                  //           const data = [...prevState.pdfData];
                  //           data[data.indexOf(oldData)] = newData;
                  //           return { ...prevState, data };
                  //         });
                  //       }
                  //     }, 600);
                  //   }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        this.setState((prevState) => {

                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                        { this.deleteVideo(oldData) }
                      }, 600);
                    }),

                }}
              />

            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
