import React, { Component } from 'react'
import { Link } from "react-router-dom"


export default class Test extends Component {
  render() {
    return (
      <React.Fragment>

        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <Link to="/addtest"> <button className="btn primary" type='button'>Add Test</button></Link>
              <Link to="/showtest"> <button className="btn primary" type='button'>Show Test</button></Link>

            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
