import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
// import AddNewPost from "./views/Subcategory";
import Test from "./views/Test";
import Questions from "./views/Questions";
// import Tables from "./views/Tables";
import notes from "./views/Notes";
import Category from "./views/Category";
import login from "./components/Login/Login";
import forgotpass from "./components/Login/Forgotpass";
import otp from "./components/Login/Otp";
import resetpassword from "./components/Login/Resetpassword";
import Notes from "./views/Notes";
import Subcategory from "./views/Subcategory";
import AddTest from "./components/Test/AddTest";
import ShowTest from "./components/Test/ShowTest"
import ShowQuestions from "./components/Questions/ShowQuestions"
// import BlogOverview from "./views/BlogOverview";
import DropdownExample from "./components/layout/MainSidebar/DropdownExample "
import AddPosts from "./components/Posts/AddPosts";
import ShowPosts from "./components/Posts/ShowPosts";
import Addadvertisement from "./components/Advertisement/Addadvertisement";
import AddBooks from "./components/Books/AddBooks";
import ShowBooks from "./components/Books/ShowBooks";
import AddJobs from "./components/Jobs/AddJobs";
import ShowJobs from "./components/Jobs/ShowJobs";
import AddInstitute from "./components/Institute/AddInstitute";
import GetFeedback from "./components/Feedback/GetFeedback";
import AddVideo from "./components/Videos/AddVideo";
import AddAffairs from "./components/CurrentAffairs/AddAffairs";
import { List } from "@material-ui/core";
import ListAffairs from "./components/CurrentAffairs/ListAffairs";
import ListAdvertisement from "./components/Advertisement/ListAdvertisement";
import ListVideos from "./components/Videos/ListVideos";
import AddOneLiner from "./components/OneLiner/AddOneLiner";
import ShowOneLiner from "./components/OneLiner/ShowOneLiner";
import Listnotes from "./views/Listnotes";
import AddExamCategory from "./components/ExamCategory/AddExamCategory";
import ListInstitute from "./components/Institute/ListInstitute";
import StudentList from "./components/StudentInfo/StudentList";
import TestSeries from './components/Test/TestSeries';
import Videoseries from "./components/Videos/Videoseries";
import Editcategory from "./views/Editcategory";
import Package from "./views/Package";
import Subscriptionlist from "./views/Subscriptionlist";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="login" />
  },
  {
    path:"/login",
    layout:login,
    component: login,
  },
  {
    path:"/forgotpass",
    layout: forgotpass,
    component: forgotpass,
  },
  {
    path:"/otp/:uniqueNo",
    layout:otp,
    component: otp,
  },
  {
    path:"/resetpassword/:uniqueNo",
    layout:resetpassword,
    component: resetpassword,
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/sub-category",
    layout: DefaultLayout,
    component: Subcategory //add-new-post
  },
  {
    path: "/test",
    layout: DefaultLayout,
    component: Test
  },
  {
    path: "/questions",
    layout: DefaultLayout,
    component: Questions
  },
  {
    path: "/notes",
    layout: DefaultLayout,
    component: Notes
  },
  {
    path: "/Listnotes",
    layout: DefaultLayout,
    component: Listnotes
  },
  {
    path: "/category",
    layout: DefaultLayout,
    component: Category
  },
  {
    path: "/AddTest",
    layout: DefaultLayout,
    component: AddTest
  },
  {
    path: "/ShowTest",
    layout: DefaultLayout,
    component: ShowTest
  },
  {
    path: "/showquestions",
    layout: DefaultLayout,
    component: ShowQuestions
  },
  {
    path: "/AddPosts",
    layout: DefaultLayout,
    component: AddPosts
  },
  {
    path: "/ShowPosts",
    layout: DefaultLayout,
    component: ShowPosts
  },
  {
    path: "/addadvertisement",
    layout: DefaultLayout,
    component: Addadvertisement
  },
  // {
  //   path: "/listadvertisement",
  //   layout: DefaultLayout,
  //   component: ListAdvertisement
  // },
  {
    path: "/addbooks",
    layout: DefaultLayout,
    component: AddBooks
  },
  // {
  //   path: "/showbooks",
  //   layout: DefaultLayout,
  //   component: ShowBooks
  // },
  {
    path: "/addjobs",
    layout: DefaultLayout,
    component: AddJobs
  },
  // {
  //   path: "/showjobs",
  //   layout: DefaultLayout,
  //   component: ShowJobs
  // },
  {
    path: "/addinstitute",
    layout: DefaultLayout,
    component: AddInstitute
  },
  {
    path: "/listinstitute",
    layout: DefaultLayout,
    component: ListInstitute
  },
  {
    path: "/getfeedback",
    layout: DefaultLayout,
    component: GetFeedback
  },
  {
    path: "/addvideo",
    layout: DefaultLayout,
    component: AddVideo
  },
  {
    path: "/listvideo",
    layout: DefaultLayout,
    component: ListVideos
  },
  {
    path: "/addaffairs",
    layout: DefaultLayout,
    component: AddAffairs
  },
  // {
  //   path: "/listaffairs",
  //   layout: DefaultLayout,
  //   component: ListAffairs
  // },
  // {
  //   path: "/addoneliner",
  //   layout: DefaultLayout,
  //   component: AddOneLiner
  // },
  // {
  //   path: "/showoneliner",
  //   layout: DefaultLayout,
  //   component: ShowOneLiner
  // },
  // {
  //   path: "/addnotes",
  //   layout: DefaultLayout,
  //   component: Addnotes
  // },
  {
    path: "/addexamcategory",
    layout: DefaultLayout,
    component: AddExamCategory
  },
  {
    path: "/studentlist",
    layout: DefaultLayout,
    component: StudentList
  },
  {
    path: "/testseries",
    layout: DefaultLayout,
    component: TestSeries
  },
  {
    path: "/videoseries",
    layout: DefaultLayout,
    component: Videoseries
  },
  {
    path: "/editcategory/:categoryId",
    layout: DefaultLayout,
    component: Editcategory
  },
  {
    path: "/package",
    layout: DefaultLayout,
    component: Package
  },
  {
    path: "/subscription",
    layout: DefaultLayout,
    component: Subscriptionlist
  },
 
];
