import MaterialTable from 'material-table'
import { ToastContainer, toast } from "react-toastify";
import React from "react";
import { Container } from "shards-react";
import axios from 'axios';
import Api_url from "../components/Constant/Api";
import Loader from 'react-loader-spinner';
import Api from '../components/Constant/Api';
import { Table } from 'reactstrap';
class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: '', field: '', editable: '' },
        {title: 'Image', field: 'imgUrl' },
        { title: '', field: '', editable: ''  },
        { title: 'Module Type', field: 'moduleType', initialEditValue: '' },

        { title: 'Category', field: 'category', initialEditValue: '' },
      ],
      addData: [
        { title: 'category', field: 'category' },
        {title: 'Image', field: 'imgUrl' },

      ],
      data: [],
      add: [],

      imageEncoding: '',
      imageName: '',
      category:'',
      fields: {},
      errors: {},
      loader:'',
      moduleType:'',
      updata:{},
      loadermodal:'',
      upmoduletype:''
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    if (this.state.category === "") {
      formIsValid = false;
      errors["category"] = "*Please Enter Category";
    }
    if (this.state.imageName === "") {
      formIsValid = false;
      errors["file"] = "*Please Choose image";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  componentDidMount() {
 this.listCategory()
  }

listCategory(){
  var pagination = {
    "pageNum": 1,
    "numPerPage": 100,
  }
  axios.post(Api_url.API_URL +'module-wise-list-category',pagination)
  .then(response => {
    for(var i=0; i<response.data.result.length; i++){
      var imgUrl= <img style={{height:'100px',width:'100px'}} src={Api.IMG_PATH+response.data.result[i].image+"/"} />
      response.data.result[i].imgUrl=imgUrl;
  }
    this.setState({ data: response.data.result })
  })
  .catch(error => {
    toast.error(error)
  })
}
listCategoryorderBy(orderBy){
  var pagination = {
    "pageNum": 1,
    "numPerPage": 10,
    "orderBy":orderBy
  }
  axios.post(Api_url.API_URL +'module-wise-list-category',pagination)
  .then(response => {
    for(var i=0; i<response.data.result.length; i++){
      var imgUrl= <img style={{height:'100px',width:'100px'}} src={Api.IMG_PATH+response.data.result[i].image+"/"} />
      response.data.result[i].imgUrl=imgUrl;
  }
    this.setState({ data: response.data.result })
  })
  .catch(error => {
    toast.error(error)
  })
}

  addCategoryData = (e) => {
    e.preventDefault();
    this.setState({loader:true})

     if (this.handleValidation()) {
      var passing = {
        "category": this.state.category,
        "moduleType":this.state.moduleType,
        "image": this.state.imageName,
        "encodedImages": this.state.imageEncoding
      }
      axios.post(Api_url.API_URL + 'add-category', passing)
        .then(res => {
          this.setState({ add: res })
          if (res.data.status === "200") {
            toast.success(res.data.message);

            this.setState({loader:false})

            this.componentDidMount();
          }
        })
        .catch(error => {
          toast.error(error)
        })
    }
    else {
      toast.error("all fields are mandatory")
    }
  
}
  

  // updateCategoryList(newData) {
   
  //   var params = {
  //     "categoryId": newData.categoryId,
  //     "category": newData.category
  //   }
  //   axios.post(Api_url.API_URL + 'update-category', params)
  //     .then(res => {
  //       toast.success(res.data.message);
  //     })
  //     .catch(error => {
  //       toast.error(error)
  //     })
  // }
  updateCategoryList = (e) =>{
    this.setState({loadermodal:true})

    e.preventDefault();
    var passdata = {
      categoryId: this.state.updata.categoryId,
      category: this.state.category,
      moduleType:this.state.upmoduletype,
      image: this.state.imageName,
      encodedImages:this.state.imageEncoding
     
    }
    axios.post(Api.API_URL + 'update-category', passdata)
      .then(res => {
        if(res.data.status==="200"){
          this.setState({loadermodal:false})
          toast.success(res.data.message);
          window.$('#modalCart').modal('hide'); 
          this.listCategory()
     
      }else{
        this.setState({loadermodal:false})
      toast.error(res.data.message);
    }
    this.setState({loadermodal:false})
      })
      .catch(error => {
        toast.error(error)
      })
  }
  DeleteCategoryList() {
    const url = Api_url.API_URL + 'delete-category/' + this.state.updata.categoryId;
    axios.delete(url)
      .then(res => {
        toast.success(res.data.message);
        this.listCategory()
        window.$('#modal_deletecategory').modal('hide');

      })
      .catch(error => {

        toast.error(error)
      })

  }


  imageHandler = (e) => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (f) => {

      var str = f.target.result;
      var array = str.split(",", 2);

      this.setState({
        imageEncoding: array[1],
        imageName: files[0].name
      })

    }
  }
 setCategory = (e) => {
   this.setState({
    [e.target.name]: e.target.value
   })
 }
 setModuletype = (e) => {
  this.setState({
    moduleType:e.target.value
  })
}
setOrderBy = (e) => {
 this.listCategoryorderBy(e.target.value)
}

updateModuletype = (e) => {
  this.setState({
    upmoduletype:e.target.value
  })
}
updatedataitem = (item) => {
  this.setState({updata:item})
}

  render() {
    if(this.state.loader ===true){
      return <div className="text-center  marginloader" ><Loader
      type="Puff"
      color="#00e600"
      height={100}
      width={100}
      
     //  timeout={3000} //3 secs

   /></div> 
    }
    return (
      <React.Fragment>
        <Container />
        <div className="container-fluid pb-5 mb-5">
          <div className="row">
            <div className="col-md-6 pt-3">
            <div className="card">
          <div className="card-body">
            
          <label>Select module-type</label><br></br>
                  <select className="form-control" onChange={this.setModuletype}  >
                  <option>Select module-type</option>   
              <option value="ibps-afo">IBPS-AFO</option>   
              <option value="agri-table">Agri-Table (Statistics)</option>  
              <option value="videos">Videos</option>   
              <option value="other">Other</option>   
                  </select><br></br>
          <label>Add-Category</label>
              <input type="text" name="category" placeholder="Enter Category" className="form-control "
              onChange={this.setCategory}
                // onChange={this.handleChange.bind(this, "category")} value={this.state.fields["category"]}
              />
              <span style={{ color: "red" }}>{this.state.errors["category"]}</span><br></br>
              <label>Upload Image</label><br></br>
              <input type="file" name="file"
              onChange={this.imageHandler}
                // onChange={this.handleChange.bind(this, "file")} value={this.state.fields["file"]}
              /><br></br>
        
              <span style={{ color: "red" }}>{this.state.errors["file"]}</span><br></br><hr></hr>
              <button type="button" className=" btn primary mb-3 mt-3" onClick={this.addCategoryData}>Upload</button>
                     
            </div>
          </div>
            </div>
    

            <div className="col-md-6 pt-3 card">
            <label> Module-wise List</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
              {/* <MaterialTable
                title="Category"
                columns={this.state.columns}
                data={this.state.data}
                addData={this.state.addData}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          { this.updateCategoryList(newData) };
                          this.setState((prevState) => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        this.setState((prevState) => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });

                        { this.DeleteCategoryList(oldData) }
                      }, 600);
                    }),
                }}
              /> */}
              

              <Table>
      <thead>
        <tr>
          <th>Edit</th>
          <th>Delete</th>
          <th>Category</th>
          <th>Module type</th>
          <th>Image</th>
        </tr>
      </thead>
      <tbody>
      {this.state.data.map((item, i) => {
                    return (
        <tr>
          <td onClick={(e) => { this.updatedataitem(item) }} data-toggle="modal" data-target="#modalCart" >
            <i className="fa fa-pencil-square-o" aria-hidden="true" ></i></td>
          <th onClick={(e) => { this.updatedataitem(item) }} data-toggle="modal" data-target="#modal_deletecategory"  ><i className="fa fa-trash" aria-hidden="true"></i></th>
          <td>{item.category} </td>
          <td>{item.moduleType} </td>
          <td><img style={{height:'100px',width:'100px'}} src={Api.IMG_PATH+item.image+"/"} /></td>
        </tr>
        )
      }, this)}
      </tbody>
    </Table>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modalCart" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        {/*Header*/}
        <div className="modal-header">
          <h4 className="modal-title" id="myModalLabel">Update Category</h4>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        {/*Body*/}
        { //Check if message failed
        (this.state.loadermodal === 'true')
          ?  <div className="text-center  marginloader" ><Loader
          type="Puff"
          color="#00e600"
          height={100}
          width={100}
          
         //  timeout={3000} //3 secs
    
       /></div>
          : <div><div className="modal-body">
          <label>Select module-type</label><br></br>
                    <select className="form-control" onChange={this.updateModuletype}  >
                    <option defaultValue={this.state.updata.moduleType}>{this.state.updata.moduleType}</option>   
                <option value="ibps-afo">IBPS-AFO</option>   
                <option value="agri-table">Agri-Table (Statistics)</option>  
                <option value="videos">Videos</option>   
                <option value="other">Other</option>   
                    </select><br></br>
            <label>Add-Category</label>
                <input type="text" name="category" placeholder="Enter Category" className="form-control "
                defaultValue={this.state.updata.category}
                onChange={this.setCategory}
                  // onChange={this.handleChange.bind(this, "category")} value={this.state.fields["category"]}
                />
                <span style={{ color: "red" }}>{this.state.errors["category"]}</span><br></br>
                <label>Upload Image</label><br></br>
                <input type="file" name="file"
                onChange={this.imageHandler}
                  // onChange={this.handleChange.bind(this, "file")} value={this.state.fields["file"]}
                /><br></br>
          
                <span style={{ color: "red" }}>{this.state.errors["file"]}</span><br></br><hr></hr>
                <button type="button" className=" btn primary mb-3 mt-3" onClick={this.updateCategoryList}>Upload</button>
                
     
          </div> </div> 
      }
        
       
      </div>
    </div>
  </div>

  <div className="modal fade" id="modal_deletecategory" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title" id="exampleModalLabel">Are you sure you want to delete ?</h4>
        <button type="button" className="close " data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="mr-2">×</span>
        </button>
      </div>
      <div className="modal-footer">
        
         <h4><button data-dismiss="modal" aria-label="Close" className="badge badge-danger pointer cancel-order pull-left pointer">Cancal</button></h4>
        
         <h4><button className="badge badge-success pointer confirm pull-right pointer" onClick={() => this.DeleteCategoryList()} >Confirm</button></h4>
 </div>
    </div>
  </div>
</div>
        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </React.Fragment>
    );
  }
}


export default Category;
