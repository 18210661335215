import React, { Component } from 'react'
// import { withRouter, Router } from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';
// import TimePicker from 'react-time-picker/dist/TimePicker';
import TimePicker from "./TimePicker"
import Api from '../Constant/Api'
import {Link} from "react-router-dom";

class ShowTest extends Component {

  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      selectedCategory: "",
      selectedSubCategory: "",
      validationError: "",
      subcategory: "",
      subCat: [],
      testtype:'',
      columns: [
        { title: <h5><b>Test Name</b></h5>, field: 'testName', initialEditValue: '' },
        { title: <h5><b>Test Mode</b></h5>, field: 'testType', initialEditValue: '' },
        { title: <h5><b>Price</b></h5>, field: 'price', initialEditValue: '' },

        { title: <h5><b>Date</b></h5>, field: 'createdAt', initialEditValue: '' },
        { title: <h5><b>Start Time</b></h5>, field: 'startTime', initialEditValue: '' },
        { title: <h5><b>End Time</b></h5>, field: 'endTime', initialEditValue: '' },
      ],
      data: [],


    }
    this.setCategory = this.setCategory.bind(this);
    this.setTesttype=this.setTesttype.bind(this);
  }



  componentDidMount() {
  //  this.listCategory()
  //  this.testList()

  }

  testList = () =>{
    var pagination = {
      "pageNum":1,
      "numPerPage":10
    }
    axios.post(Api.API_URL + 'test-list' ,pagination)
      .then(res => {
        // console.log("test res: ", res.data.result);
       this.setState({
        data:res.data.result
       })
      })
  }

  listCategory = () =>{
    axios.get(Api.API_URL + 'list-category')
    .then(res => {
      if (res.data.status === "200") {
        let categoriesFromApi = res.data.result.map(cat => {
          return { value: cat.categoryId, display: cat.category };
        });
        this.setState({
          categories: [
            {
              value: "",
              display: "Select Category"
            }
          ].concat(categoriesFromApi)
        });
      }
      else {
        toast.error(res.data.message);
      }
    })
    .catch(error => {
      toast.error(error);
    });
  }

  setSubCategory = (e) => {

    this.setState({ [e.target.name]: e.target.value });

  }

  // setParameter = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  //   console.log("set test", e.target.value)
  // }


  fetchSubcategory(selectedCategory) {
    // console.log("fetchsub: ", selectedCategory)
    if (selectedCategory !== '') {
      var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
      axios.get(url)
        .then(res => {
          if (res.data.status === "200") {
            // console.log("result array: ", res.data.result);
            let subCategoriesFromApi = res.data.result.map(cat => {
              return { value: cat.subcategoryId, display: cat.subcatName };
            });
            this.setState({
              subCat: [
                {
                  value: "",
                  display: "Select Sub-Category"
                }
              ].concat(subCategoriesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }

  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    // console.log("setcategory",e.target.value)
    this.fetchSubcategory(e.target.value);
  }

  setTesttype(e){
    const testtype=e.target.value

    this.ShowTest(testtype);
  }
  setSubCategory = (e) => {
    this.setState({
      selectedSubCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite sub category"
          : ""
    })


  }


  // linking() {
  //   // this.props.history.push('/AddTest');
  // }

  ShowTest = (testtype) =>{
    var pagination = {
      pageNum: 1,
      numPerPage: 50,
      orderBy:testtype
    }

    var url = Api.API_URL + "subcategory-wise-test?subcategoryId="+this.state.selectedSubCategory;
    axios.post(url , pagination)
    .then(res=>{

      const data = res.data.result
      this.setState({
        data:res.data.result
       })
      this.setState({
        data
      })

    })
  }

  deleteTest = (oldData) =>{
        axios.delete(Api.API_URL + 'delete-test/' + oldData.testId)
        .then(res => {
            if(res.data.statu === "200"){
                toast.success(res.data.message)
            }
            else{
                toast.error(res.data.message)
            }

          })
          .catch(error => {
            toast.error(error)
          })
  }
  updateTest = (oldData) =>{
    var passdata = {
      testId:parseInt(oldData.testId),
      "testName": oldData.testName,
      "testType": oldData.testType,
      "price":this.state.price

      
    }
        axios.post(Api.API_URL + 'update-test',passdata )
        .then(res => {
            if(res.data.statu === "200"){
                toast.success(res.data.message)
            }
            else{
                toast.error(res.data.message)
            }

          })
          .catch(error => {
            toast.error(error)
          })
  }
  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api.API_URL +'module-wise-list-category',pagination)
    .then(response => {
  
      this.setState({categories: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })
  }
  render() {
    return (
      <React.Fragment>
<div className="row mr-2">
  <div className="col-3"></div>
  <div className="col-3"></div>

  <div className="col-3">  <h5 className="text-muted pl-5 pt-4 text-right">
          <Link to="/testseries" className="dropdown-item"><span className="material-icons" >add</span>Add Test series</Link>
        </h5></div>

  <div className="col-3">  <h5 className="text-muted pl-5 pt-4 text-right">
          <Link to="/addtest" className="dropdown-item"><span className="material-icons" >add</span>Add Tests</Link>
        </h5></div>


</div>

      
          <div className="form-group">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-md-12 ">
                <div className="card">
                  <div className="card-body">
                  <h5 className="text-muted ">Test list</h5><br></br>
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                    <label className="text-muted">Select Category</label>
                  <select
                  value={this.state.selectedCategory}
                  onChange={this.setCategory}
                  className="form-control">
                  {this.state.categories.map(cat => (
                    <option
                      key={cat.value}
                      value={cat.categoryId}
                    >
                      {cat.category}
                    </option>
                  ))}
                </select><br></br>
                <label className="text-muted">Select Sub-category</label>
                <select
                  value={this.state.selectedSubCategory}
                  onChange={this.setSubCategory}
                  className="form-control">
                  {this.state.subCat.map(cat => (
                    <option
                      key={cat.value}
                      value={cat.value}
                    >
                      {cat.display}

                    </option>
                  ))}
                </select><br></br>
                <label className="text-muted">Select Test Type</label>
                <select
                   onChange={this.setTesttype}
                  className="form-control">
                    <option >Select</option>
                    <option value="onlineTest">Online Test</option>
                    <option value="offlineTest">Offline Test</option>
                    <option value="freeTest">Free Test</option>

                </select><br></br>

                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

        <div className ="container pb-5 mb-5">
          <div className="row">
            <div className="col-md-12">
                        <MaterialTable
          title="Test Time "
          columns={this.state.columns}
          data={this.state.data}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve) => {
            //     // console.log("From onRowAdd")
            //     setTimeout(() => {
            //       resolve();
            //       this.setState((prevState) => {
            //         const add = [...prevState.add];
            //         add.push(newData);
            //         {this.addCategoryData(newData)}
            //         return { ...prevState, add };
            //       });
            //       // { this.addCategoryData(newData) }
            //     }, 600);
            //   }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                // console.log("Resolve: ", resolve);
                setTimeout(() => {
                  resolve();
                  if (oldData) {
                    // console.log("oldData: ", oldData);
                    // console.log("newData: ", newData);
                    { this.updateTest(newData) }
                    this.setState((prevState) => {
                      // console.log("prevState: ", prevState);
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  this.setState((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });

                { this.deleteTest(oldData) }
                }, 600);
              }),
          }}
        />
              </div>
              </div>
              </div>

      </React.Fragment>

    )
  }
}

export default ShowTest
