import React, { Component } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import Api from '../Constant/Api'
import Api_url from '../Constant/Api'
import { ToastContainer, toast } from "react-toastify";
import Loader from 'react-loader-spinner';



export default class TestSeries extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      testSeriesName:'',
      validity:'',
      price:'',
      categories: [],
      selectedCategory: "",
      selectedSubCategory: "",
      subCat:[],
      testlist:[],
      testGrpList:[],
      loader:'',

      columns: [
        { title: <h5><b>Test Series Name</b></h5>, field: 'testSeriesName', initialEditValue: '' },
        { title: <h5><b>validity</b></h5>, field: 'validity', initialEditValue: '' },
        { title: <h5><b>price</b></h5>, field: 'price', initialEditValue: '' },
        
      ],
      data: [],

       
    }
  } 
  componentDidMount(){
    // this.listCategory()
    // this.testserieslist();

  }
  testlist(selectedSubCategory){
    axios.post(Api_url.API_URL +'subcategory-wise-test?subcategoryId='+selectedSubCategory,{  "pageNum":1,"numPerPage":100 })
    .then(response => {
      this.setState({ testlist: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })

  }
  testSerieslist(selectedSubCategory) {
    axios.post(Api_url.API_URL +'subcategory-wise-test-series?subcategoryId='+selectedSubCategory,{  "pageNum":1,"numPerPage":100 })
      .then(response => {
        this.setState({ data: response.data.result })
      })
      .catch(error => {
        toast.error(error)
      })
  }
  AddtestSeries= (e) =>  {
    toast.configure();

    this.setState({loader:true})
    e.preventDefault();
   var testdata={
      "testSeriesName":this.state.testSeriesName,
      "validity":this.state.validity,
      "price":this.state.price,
      "testGrpList":this.state.testGrpList,
      "subcategoryId":this.state.selectedSubCategory
     
    }
    axios.post(Api_url.API_URL +'add-test-series-subcat-wise',testdata)
      .then(response => {
        if(response.data.status==="200"){
          this.setState({ loader:false })
          toast.success("Added Sucessfully")
// this.testserieslist();
        }
      })
      .catch(error => {
        this.setState({loader:false})

        toast.error(error)
      })
  }
 
testserieslist = () => {
  axios.get(Api.API_URL+'test-series-list',{pageNum:"1",numPerPage:"100"})
    .then(res => {
      if (res.data.status === "200") {
      this.setState({  data:res.data.result });
      }
      else {
        toast.error(res.data.message);
      }
    })
    .catch(error => {
      toast.error(error);
    });
}

setCategory = (e) => {
  this.setState({
    selectedCategory: e.target.value,
    validationError:
      e.target.value === ""
        ? "You must select your favourite category"
        : ""
  })
  this.fetchSubcategory(e.target.value);
}


fetchSubcategory(selectedCategory) {
  // console.log("fetchsub: ", selectedCategory)
  if (selectedCategory !== '') {
    var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
    axios.get(url)
      .then(res => {
        if (res.data.status === "200") {
          let subCategoriesFromApi = res.data.result.map(cat => {
            return { value: cat.subcategoryId, display: cat.subcatName };
          });
          this.setState({
            subCat: [
              {
                value: "",
                display: "Select Sub-Category"
              }
            ].concat(subCategoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }
}


setSubCategory = (e) => {
  this.setState({
    selectedSubCategory: e.target.value,
    validationError:
      e.target.value === ""
        ? "You must select your favourite sub category"
        : ""
  })
  this.testSerieslist(e.target.value)

  // this.testlist(e.target.value)

}

   onChangetestseries = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  onChangeselecttest = (e,i) => {
  const test={'testId':e.target.value}
  this.state.testGrpList.push(test);
  }
  DeletetestseriesList(oldData) {
    const url = Api_url.API_URL + 'delete-test-series/' + oldData.testSeriesId;
    axios.delete(url)
      .then(res => {
        toast.success(res.data.message);
      })
      .catch(error => {

        toast.error(error)
      })

  }
  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api.API_URL +'module-wise-list-category',pagination)
    .then(response => {
  
      this.setState({categories: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })
  }
    render() {
      if(this.state.loader ===true){
        return <div className="text-center  marginloader" ><Loader
        type="Puff"
        color="#00e600"
        height={100}
        width={100}
        
        timeout={4000} //3 secs
  
     /></div> 
      }
        return (
            <div>
              <div className="row mr-2">
                <div className="col-6">
                <div className="form-group">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-md-12 ">
                <div className="card">
                  <div className="card-body">
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                  <label>Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.category}
                        </option>
                      ))}
                    </select><br></br>

                    <label>Select Sub-category</label>
                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select><br></br>
                  <label>Series Name</label>
              <input type="text" name="testSeriesName" placeholder="Enter series number" className="form-control "
              onChange={this.onChangetestseries}
              /><br></br>
                    <label>Validity in months</label>
              <input type="number" min="0" name="validity" placeholder="Enter validity-number" className="form-control "
              onChange={this.onChangetestseries}
              /><br></br>
                       <label>Price</label>
              <input type="number" min="0" name="price" placeholder="Enter validity-number" className="form-control "
              onChange={this.onChangetestseries}
              /><br></br>
<div className="btn-group">
  <button className="btn form-group dropdown-toggle test-primary border-dark" data-toggle="dropdown">
    select test
  </button>
  
  <ul className="dropdown-menu" style={{padding: '10px'}} id="myDiv" >
  {this.state.testlist.map((item, i) => {
          return (
    <li key={i}><p><input type="checkbox" defaultValue={item.testId} onChange={this.onChangeselecttest} /> {item.testName} </p></li>
    )
  }, this)}
  </ul>


</div>
<br></br>
<button type="button" className=" btn primary " onClick={this.AddtestSeries}>Add</button>
<br></br><br></br><br></br>
                  </div>
                </div>

              </div>
            </div>
            
          </div>
          </div>
          </div>
          <div className="col-6 pt-3">

          <div className="card">
                  <div className="card-body">
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                  <label>Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.category}
                        </option>
                      ))}
                    </select><br></br>

                    <label>Select Sub-category</label>
                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select><br></br>
                    </div>
                    </div>
                    <br></br>
          <MaterialTable
          className="mr-2"
                title="Test series List"
                columns={this.state.columns}
                data={this.state.data}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          { this.updateSubcat(newData) }
                          this.setState((prevState) => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        this.setState((prevState) => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                        { this.DeletetestseriesList(oldData) }
                      }, 600);
                    }),
                }}
              />
          </div>
              </div>

            </div>
        )
    }
}
