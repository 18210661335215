import React, { Component } from 'react'
import axios, { post } from 'axios';
import {Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Api from '../Constant/Api'
import Loader from 'react-loader-spinner';


export default class AddVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            VideoType: '',
            title: '',
            Description: '',
            url: '',
            thumbnailImage:'',
            fields: {},
            errors: {},
            display: false,
            categories: [],
            loader:'',
            price:'',
            imageName: '',        
            selectedCategory: "",
            selectedSubCategory: "",
      subCat: [],

        }
        // this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    // validation

    handleValidation() {
        
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (this.state.file === '') {
            formIsValid = false;
            errors["file"] = "*Please Choose File";
        }

        if(this.state.url===null){
            if (this.state.url === '') {
                formIsValid = false;
                errors["videoUrl"] = "*Please Enter Url";
            }
    
        }
     
        if (this.state.Description === '') {
            formIsValid = false;
            errors["description"] = "*Please Enter Description";
        }

        if (this.state.title === '') {
            formIsValid = false;
            errors["title"] = "*Please Enter Title";
        }

        // if (this.state.VideoType === '') {
        //     formIsValid = false;
        //     errors["videoType"] = "*Please Choose Video Type";
        // }

        this.setState({ errors: errors });
        return formIsValid;
    }
    // handleChange(field, e) {
    //     let fields = this.state.fields;
    //     fields[field] = e.target.value;
    //     this.setState({ fields });
    // }
    onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        this.fileUpload(this.state.file).then((response) => {
        })

    }
    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }
    imageHandler = (e) => {
      let files = e.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
  
      reader.onload = (f) => {
  
        var str = f.target.result;
        var array = str.split(",", 2);
  
        this.setState({
          imageEncoding: array[1],
          imageName: files[0].name
        })
  
      }
    }
    fileUpload(e) {
        e.preventDefault();
        // if (this.handleValidation()) {
            if(this.state.file.size > 10000000){
                toast.error("please add video less than 10 mb")
            }
            this.setState({loader:true})
            const formData = new FormData();
            formData.append('video', this.state.file);
            formData.append('description', this.state.Description);
            formData.append('title', this.state.title);
            formData.append('subcategoryId', this.state.selectedSubCategory);
            formData.append("thumbnailImage", this.state.imageName);
            formData.append("videoUrl", this.state.url);
            formData.append("price", this.state.price);

            formData.append("videoType", this.state.VideoType);
            // const config = {
            //     headers: {
            //         'content-type': 'multipart/form-data'
            //     }
            // }
            axios.post(Api.API_URL + 'add-subcategory-wise-video', formData)
                .then((res) => {
                    if (res.data.status === "200") {
                        toast.success(res.data.message)
                        this.setState({loader:false})

                    }
                    else {
                        toast.error(res.data.message)
                        this.setState({loader:false})

                    }
                })
                .catch((err) => {
                  this.setState({loader:false})

                    toast.error(err)
                })
        // } else {

        //     toast.error("all fields are mandatory")

        // }
    }


    setVideoType = (event) => {
        this.setState({
            VideoType: event.target.value
        });
        if (event.target.value === "online") {

            this.setState({
                display: true
            })
        }
        if (event.target.value === "offline") {

            this.setState({
                display: false
            })
        }
    }
    setVideoInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    componentDidMount(){
      this.listCategory()
    }
  listCategory = () => {
    axios.get(Api.API_URL + 'list-category')
      .then(res => {
        if (res.data.status === "200") {
          let categoriesFromApi = res.data.result.map(cat => {
            return { value: cat.categoryId, display: cat.category };
          });
          this.setState({
            categories: [
              {
                value: "",
                display: "Select Category"
              }
            ].concat(categoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }

  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    this.fetchSubcategory(e.target.value);
  }


  fetchSubcategory(selectedCategory) {
    if (selectedCategory !== '') {
      var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
      axios.get(url)
        .then(res => {
          if (res.data.status === "200") {
            let subCategoriesFromApi = res.data.result.map(cat => {
              return { value: cat.subcategoryId, display: cat.subcatName };
            });
            this.setState({
              subCat: [
                {
                  value: "",
                  display: "Select Sub-Category"
                }
              ].concat(subCategoriesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }


  setSubCategory = (e) => {
    this.setState({
      selectedSubCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite sub category"
          : ""
    })
  }

  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api.API_URL +'module-wise-list-category',pagination)
    .then(res => {
      if (res.data.status === "200") {
        let categoriesFromApi = res.data.result.map(cat => {
          return { value: cat.categoryId, display: cat.category };
        });
        this.setState({
          categories: [
            {
              value: "",
              display: "Select Category"
            }
          ].concat(categoriesFromApi)
        });
      }
      else {
        toast.error(res.data.message);
      }
    })
    .catch(error => {
      toast.error(error);
    });
  }


  

    render() {
      if(this.state.loader ===true){
        return <div className="text-center  marginloader" ><Loader
        type="Puff"
        color="#00e600"
        height={100}
        width={100}
        
       //  timeout={3000} //3 secs
  
     /></div> 
      }
        return (
            <React.Fragment>
                 <h5 className="text-muted pl-5 pt-4 text-right">
                <Link to="/listvideo" className="dropdown-item"><span className="material-icons" >add</span> Video List</Link>
              </h5>
                <h5 className="text-muted pl-5 pt-4">Add Video</h5>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-5 mb-5">
                            <div className="card">
                                <div className="card-body">
                                <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                                <label>Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}
                        </option>
                      ))}
                    </select><br></br>

                    <label>Select Sub-category</label>
                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select><br></br>
                                    <label>Title</label>
                                    <input type="text" className="form-control" name="title" placeholder="title"
                                        onChange={this.setVideoInput}
                                    // onChange={this.handleChange.bind(this, "title")} value={this.state.fields["title"]}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["title"]}</span><br></br>
                                    <label>Price</label>
                                    <input type="number" className="form-control" name="price" placeholder="price"
                                        onChange={this.setVideoInput}
                                    /><br></br>
                                    <label>Description</label>
                                    <textarea type="text" className="form-control" name="Description" placeholder="description"
                                        onChange={this.setVideoInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["Description"]}</span><br></br>
                                    
                                                              
                                    <ul>
                                        <label>Video type</label>
                                        <li>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="online"
                                                    checked={this.state.VideoType === "online"}
                                                    onChange={this.setVideoType}
                                                // onChange={this.handleChange.bind(this, "online")} value={this.state.fields["online"]}
                                                />
                                   &nbsp; Online
                                </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="offline"
                                                    checked={this.state.VideoType === "offline"}
                                                    onChange={this.setVideoType}
                                                // onChange={this.handleChange.bind(this, "offline")} value={this.state.fields["offline"]}
                                                />
                                   &nbsp; Offline
                                </label><br></br><br></br>
                                { //Check if message failed
                                   (this.state.VideoType === "online")
                                   ? <div>                          
                                     <label>Add Thumbnail</label><br></br>                 
                                   <input type="file" name="file" name="thumbnailImage" onChange={(e) => this.imageHandler(e)} />
                                    </div> 
                                   : <div> </div> 
                                    }
                                    <br></br><br></br>
                                {
        (this.state.VideoType === "offline")
          ? <div>  <label>Video Url</label>
          <input type="text" className="form-control" name="url" placeholder="url"
              onChange={this.setVideoInput}
          // onChange={this.handleChange.bind(this, "url")} value={this.state.fields["url"]}
          /><br></br>

          <span style={{ color: "red" }}>{this.state.errors["url"]}</span><br></br>
          <button type="button" className="btn primary" onClick={this.fileUpload} >Add Video</button>

</div> 
          : <div> {this.state.display && <label>Video File Upload</label>}<br></br>
          {this.state.display && <input type="file" name="file"
              onChange={this.onChange}
          />}<br></br>

          {this.state.display && <span style={{ color: "red" }}>{this.state.errors["file"]}</span>}<br></br>

          <button type="button" className="btn primary" onClick={this.fileUpload} >Add Video</button>
</div> 
      }
       <span style={{ color: "red" }}>{this.state.errors["VideoType"]}</span>
                                        </li>
                                    </ul>

                                   
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        )
    }

}
