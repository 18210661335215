import MaterialTable from 'material-table';
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import Api_url from "../Constant/Api";
import axios from 'axios';
class AddExamCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: '', field: '', initialEditValue: '' },
                { title: '', field: '', initialEditValue: '' },
                { title: <h6><b>Exam-Category</b></h6>, field: 'examCategory', initialEditValue: '' },
            ],
            addData: [
                { title: 'examCategory', field: 'examCategory' },
            ],
            data: [],
            add: [],
        }
    }
    componentDidMount() {
        axios.get(Api_url.API_URL + 'list-exam-category')
            .then(response => {
                this.setState({ data: response.data.result })
            })
            .catch(error => {
                console.log(error)
            })
    }
    addExamCategory(newData) {
        var passdata = {
            "examCategory": newData.examCategory
        }  
        axios.post(Api_url.API_URL + "add-exam-category", passdata)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                    this.componentDidMount();
                }
                else {
                    toast.error("res.data.message")
                }
                this.setState({ add: res })
            })
            .catch(error => {
                toast.error(error)
            })
    }
    updateExamCategory(newData) {
        var params = {
            "examCategoryId": newData.examCategoryId,
            "examCategory": newData.examCategory
        }
        axios.post(Api_url.API_URL + 'update-exam-category', params)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error("res.data.message")
                }
            })
            .catch(error => {
                toast.error(error)
            })
    }
    DeleteExamCategory(oldData) {
        const url = Api_url.API_URL + 'delete-exam-category/' + oldData.examCategoryId;
        axios.delete(url).then(res => {
            if (res.data.status === "200") {
                toast.success(res.data.message)
            }
            else {
                toast.error("res.data.message")
            }
            this.setState({ data: res })

        })
            .catch(error => {
                toast.error(error)
            })

    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid pt-5 pb-5 mb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <MaterialTable
                                title="Exam-Category"
                                columns={this.state.columns}
                                data={this.state.data}
                                addData={this.state.addData}
                                editable={{
                                    onRowAdd: (newData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState((prevState) => {
                                                    const add = [...prevState.add];
                                                    add.push(newData);
                                                    return { ...prevState, add };
                                                });
                                                { this.addExamCategory(newData) }
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    { this.updateExamCategory(newData) }
                                                    this.setState((prevState) => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState((prevState) => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });

                                                { this.DeleteExamCategory(oldData) }
                                            }, 600);
                                        }),
                                }}
                            />
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        );
    }
}


export default AddExamCategory;

