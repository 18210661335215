import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';
import Api_url from '../Constant/Api'
import Loader from 'react-loader-spinner';

import { Table } from 'reactstrap';

export default class Addadvertisement extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            Image : <img style={{height:'100px',width:'100px'}} src="Agriguru logo.ico" />,
            encodedimage: '',
            image: '',
            linkUrl:'',
            loader:'',
            advertisementImages: {},
            errors: {},
            fields: {},
            columns: [
                // {title:'Image',field: <div><img src="assets/preview.png"/></div>, initialEditValue:<div><img src="assets/preview.png"/></div> },
                { title: '', field: '', initialEditValue: '' },
                { title: <h5><b>Image</b></h5>, field: 'imgUrl' },
                { title: <h5><b>Link url</b></h5>, field: 'linkUrl' },

                { title: <h5><b>Created At</b></h5>, field: 'createdAt', initialEditValue: '' },

            ],
            data: [],
            allImages: [],
        }
    }

    componentDidMount() {
        var pagination = {
            "pageNum": 1,
            "numPerPage": 10
        }
        var url = Api_url.API_URL + 'list-advertisement';
        axios.get(url, pagination)
            .then(res => {
                for(var i=0; i<res.data.result.length; i++){
                    var imgUrl= <img style={{height:'100px',width:'100px'}} src={"http://agriguru.avertastrategy.com/v1/get-image/"+res.data.result[i].image+"/"} />
                    res.data.result[i].imgUrl=imgUrl;
                }     
                this.setState({ data: res.data.result })           
            })
            .catch(error => {
                console.log(error)
            })
    }
    // fetchImages = () => {
    //     let imagesFromApi = this.state.data.map((Image) => {
    //         axios.get(Api_url.API_URL + "get-image/" + Image.image + "/")
    //             .then(res => {
    //                 return { xyz: res.data }
    //             })
    //             this.onChange()
    //     })

    //      imagesFromApi.map((img)=> {
    //          console.log(img.xyz);
    //      })
    //     this.setState({
    //         allImages: imagesFromApi,
    //         show: true
    //     })
    // }

    // handleValidation() {
    //     let fields = this.state.fields;
    //     let errors = {};
    //     let formIsValid = true;

    //     if (this.state.advertisementImages === '') {
    //         formIsValid = false;
    //         errors["advertisementImages"] = "*Please Choose Image";
    //     }
        
    // }

    onChange(e) {
        let files = e.target.files
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (f) => {

            var str = f.target.result;

            var array = str.split(",", 2);


            this.setState({
                advertisementImages:({ "image": files[0].name, "encodedImage": array[1] })
            })

        }
    }
    onChangelinkurl(e) {
       this.setState({linkUrl:e.target.value})

    
    }
    upload = (e) => {
        e.preventDefault();
this.setState({loader:true})
        // if (this.handleValidation()) {
            var formData =this.state.advertisementImages
        formData.linkUrl=this.state.linkUrl
          
            const url = Api_url.API_URL + "add-advertisement";

            return axios.post(url, formData)
                .then(res => {
                    if (res.data.status === "200"){
                        toast.success("Upload successfully!");
                        this.setState({loader:false})

                        this.componentDidMount();
                    } 
                    else{
                        this.setState({loader:true})

                        toast.error(res.data.message);
                    }
                })
        }
        // else {
        //     toast.error("all fields are mandatory")
        // }
    

    Deleteimage = (oldData) => {

           axios.delete(Api_url.API_URL + "delete-advertisement/"+ oldData.advertisementId,)
            .then(res => {
                if(res.data.status === "200"){
                    toast.success(res.data.message)
                }else{
                    toast.error(res.data.message)
                }
            })
    }

    render() {
        if(this.state.loader ===true){
            return <div className="text-center  marginloader" ><Loader
            type="Puff"
            color="#00e600"
            height={100}
            width={100}
            
           //  timeout={3000} //3 secs
      
         /></div> 
          }
        return (
            <React.Fragment>
                <div className="container-fluid pt-3 ">
                    <div className="row">
                        <div className="col-md-4 pb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5>Advertisement Url</h5><br></br>
                                    <input className="form-control" type="text" name="linkUrl" onChange={(e) => this.onChangelinkurl(e)} placeholder="paste Advertisement url here" /><br></br><hr></hr>
                                    <h3>Upload Image</h3><br></br>

                                    <input type="file" name="file" onChange={(e) => this.onChange(e)} /><br></br><hr></hr>
                                    {/* <span style={{ color: "red" }}>{this.state.errors["advertisementImages"]}</span><br></br><hr></hr> */}

                                    <button type="button" className=" btn primary mb-3 mt-3" onClick={this.upload}>Add Image</button><br></br>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 pb-5 mb-5">
                            <MaterialTable
                                title="List Advertisement"
                                columns={this.state.columns}
                                data={this.state.data}
                             
                                editable={{
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState((prevState) => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });

                                                { this.Deleteimage(oldData) }
                                            }, 600);
                                        }),
                                }}
                            />

                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />

            </React.Fragment>
        )
    }
}
