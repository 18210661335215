import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { withRouter } from 'react-router';

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    this.loginUser = JSON.parse(localStorage.getItem('agriguruUser'));
    this.state = {
      visible: false,
      userdata: this.loginUser
    };
    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  logOut = () => {
    this.loginUser = JSON.parse(localStorage.getItem('userData'));
    localStorage.clear();
    this.props.history.push('/');
  }

  render() {
   
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/profile icon.png")}
            alt="User Avatar"
          />{" "}
        
      { //Check if message failed
        (this.state.userdata === null)
          ? <div></div>
      : <span className="d-none d-md-inline-block">{this.state.userdata.email}</span>
      }
          {/* <span className="d-none d-md-inline-block">{this.state.userdata}</span> */}
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible} className="pl-5">
          <DropdownItem tag={Link} to="user-profile-lite">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={this.logOut} >
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

export default withRouter(UserActions);