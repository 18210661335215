import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import Api from '../Constant/Api'

export default class AddPosts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            desc: "",
            fields: {},
            errors: {}
        }
    }


    addposts = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            var passprops = {
                "postsTitle": this.state.title,
                "postsDescription": this.state.desc
            }

            console.log("addpost", passprops)
            axios.post(Api.API_URL + 'add-posts', passprops)
                .then(res => {
                    if (res.data.status === "200") {
                        toast.success(res.data.message)
                    }
                    else {
                        toast.error(res.data.message)
                    }
                })
        } else {
            toast.error("Cannot be empty ")
        }
    }
    // handleChange(field, e){         
    //     let fields = this.state.fields;
    //     fields[field] = e.target.value;        
    //     this.setState({fields});
    // }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (this.state.title === '') {
            formIsValid = false;
            errors["title"] = "*Please insert title";
        }
        if (this.state.desc === '') {
            formIsValid = false;
            errors["desc"] = "*Please insert desc";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    setTitle = (e) => {

        this.setState({ [e.target.name]: e.target.value });

    }


    render() {
        return (
            <React.Fragment>
                <h5 className="text-muted pl-5 pt-4">Add Post</h5>
                <div className="container-fluid pt-5 pb-5">
                    <div className="row">
                        <div className="col-md-12 pb-5 mb-5">
                            <div className="card">
                                <div className="card-body">
                                    <label>Post Tittle</label>
                                    <input type="text" placeholder="Post Tittle" name="title" className="form-control"
                                        onChange={this.setTitle}
                                    //    onChange={this.handleChange.bind(this, "title")} value={this.state.fields["title"]}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["title"]}</span><br></br>

                                    <label>Add Description</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="desc"
                                        onChange={this.setTitle}
                                    //    onChange={this.handleChange.bind(this, "desc")} value={this.state.fields["desc"]}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["desc"]}</span><br></br>

                                    <button type="button" className="btn primary mt-2" onClick={this.addposts}>Add Post</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        )
    }
}
