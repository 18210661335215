import React, { Component } from 'react'
import axios from 'axios'
import MaterialTable from 'material-table';
import Api from '../Constant/Api'
import { toast } from 'react-toastify';

export default class ShowPosts extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            columns: [
                { title: <h5><b>Post Title</b></h5>, field: 'postsTitle', initialEditValue: '' },
                { title: <h5><b>Post Description</b></h5>, field: 'postsDescription', initialEditValue: '' },
                { title: <h5><b>Date</b></h5>, field: 'createdAt', initialEditValue: '' },
               
              ],
              data: [],
            
        }
    }
    componentDidMount(){
        var pagination = {
            "pageNum":1,
	        "numPerPage":10
        }
        
        axios.post(Api.API_URL + 'list-posts',pagination)
        .then(res => {
            console.log("res",res)
            this.setState({ data: res.data.result })
          })
          .catch(error => {
            console.log(error)
          })
    }

    deletePost = (oldData) =>{
        console.log("olddata",oldData)
        axios.delete(Api.API_URL + 'list-posts',oldData)
        .then(res => {
            if(res.data.statu === "200"){
                toast.success(res.data.message)
            }
            else{
                toast.error(res.data.message)
            }
           
          })
          .catch(error => {
            toast.error(error)
          })
    }
    
  

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid pt-3 mb-3 pb-5 mb-5">
                    <div className="row">
                        <div className="col-md-12">
                                <MaterialTable
                                title="Show Posts"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowAdd: (newData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("From onRowAdd")
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const add = [...prevState.add];
                                    //         add.push(newData);
                                    //         {this.addCategoryData(newData)}
                                    //         return { ...prevState, add };
                                    //       });
                                    //       // { this.addCategoryData(newData) }
                                    //     }, 600);
                                    //   }),
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("Resolve: ", resolve);
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         // console.log("oldData: ", oldData);
                                    //         // console.log("newData: ", newData);
                                    //         { this.updateCategoryList(newData) }
                                    //         this.setState((prevState) => {
                                    //           // console.log("prevState: ", prevState);
                                    //           const data = [...prevState.data];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    onRowDelete: (oldData) =>
                                      new Promise((resolve) => {
                                        setTimeout(() => {
                                          resolve();
                                          this.setState((prevState) => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                          });

                                        { this.deletePost(oldData) }
                                        }, 600);
                                      }),
                                }}
                            />
                                </div>
                            </div>
                </div>
            </React.Fragment>
        )
    }
}
