export default{
  // API_URL: 'http://agriguru.avertastrategy.com/v1/',
  // IMG_PATH: 'http://agriguru.avertastrategy.com/v1/get-image/',
  // PDF_PATH: 'http://agriguru.avertastrategy.com/v1/getDocument/',
  // VIDEO_PATH: 'http://agriguru.avertastrategy.com/v1/get-file/'
  API_URL: 'https://agriguru.avertastrategy.com/v1/',
  IMG_PATH: 'https://agriguru.avertastrategy.com/v1/get-image/',
  PDF_PATH: 'https://agriguru.avertastrategy.com/v1/getDocument/',
  VIDEO_PATH: 'https://agriguru.avertastrategy.com/v1/get-file/'
}
