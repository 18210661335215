// import React from "react";
import { Container } from "shards-react";
import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';
import Api from '../components/Constant/Api';
import Loader from 'react-loader-spinner';
import {Link} from "react-router-dom";


export default class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      selectedCategory: "",
      selectedSubCategory: "",
      selectedNotes: "",
      validationError: "",
      subcategory: "",
      title:"",
      description:"",
      subCat: [],
      notes: [],
      documentName: "",
      encodedDocument: "",
      documentType: "",
      showDocuments: "",
      type: [{ "value": "", "display": "select document type" }, { "value": "notes", "display": "notes" }, { "value": "syllabus", "display": "syllabus" }, { "value": "previousPaper", "display": "previousPaper" }, { "value": "oneLiner", "display": "One-liner" }, { "value": "agriTable", "display": "Agri-Table" }, { "value": "IBPS-AFO", "display": "IBPS-AFO" }],
      orderby: [{ "value": "", "display": "select document type" }, { "value": "notes-pdf", "display": "notes-pdf" }, { "value": "syllabus-pdf", "display": "syllabus-pdf" }, { "value": "previous-paper-pdf", "display": "previous-paper-pdf" }, { "value": "one-liner-pdf", "display": "One-liner" }, { "value": "agri-table-pdf", "display": "Agri-Table" }, { "value": "IBPS-AFO-pdf", "display": "IBPS-AFO" }],
      columns: [
        { title: '', field: '', initialEditValue: '' },
        { title: 'Document type', field: 'documentType', initialEditValue: '' },
        { title: 'Documents', field: 'document', initialEditValue: '' },
      ],
      data: [],
      isShowTable: false,
      fields: {},
      errors: {},
      loader:''
    }
    this.setCategory = this.setCategory.bind(this);
    // this.fetchSubcategory = this.fetchSubcategory.bind(this);
  }
  onChangefortitle = (e) => {
    this.setState({ title: e.target.value });
  }
  onChangeford = (e) => {
    this.setState({ description: e.target.value });
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (this.state.selectedCategory === '') {
      formIsValid = false;
      errors["selectedCategory"] = "*Please Select Category";
    }
    if (this.state.selectedSubCategory === '') {
      formIsValid = false;
      errors["selectedSubCategory"] = "*Please Select Sub-Category";
    }
    if (this.state.documentType === '') {
      formIsValid = false;
      errors["documentType"] = "*Please Select Document Type";
    }
    if (this.state.documentName === '') {
      formIsValid = false;
      errors["documentName"] = "*Please Choose documents";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  componentDidMount() {
    // this.listCategory()
  }
  listCategory = () => {
    axios.get(Api.API_URL + 'list-category')
      .then(res => {
        if (res.data.status === "200") {
          let categoriesFromApi = res.data.result.map(cat => {
            return { value: cat.categoryId, display: cat.category };
          });
          this.setState({
            categories: [
              {
                value: "",
                display: "Select Category"
              }
            ].concat(categoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }
  setSubCategory = (e) => {
    this.setState({
      selectedSubCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite sub category"
                : ""
    })
  }
  setNotes = (e) => {
    this.setState({
      selectedNotes: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select a document"
          : ""
    })
  }
  fetchSubcategory(selectedCategory) {
    if (selectedCategory !== '') {
      var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
      axios.get(url)
        .then(res => {
          if (res.data.status === "200") {
            let subCategoriesFromApi = res.data.result.map(cat => {
              return { value: cat.subcategoryId, display: cat.subcatName };
            });
            this.setState({
              subCat: [
                {
                  value: "",
                  display: "Select Sub-Category"
                }
              ].concat(subCategoriesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }
  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    this.fetchSubcategory(e.target.value);
  }
  upload = (e) => {
    toast.configure();

    e.preventDefault();
    if (this.handleValidation()) {
      var formData = {
        "subcategoryId": this.state.selectedSubCategory,
        "document": this.state.documentName,
        "documentType": this.state.documentType,
        "encodedDocument": this.state.encodedDocument,
        "title":this.state.title,
        "description":this.state.description
      }
      const url = Api.API_URL + "add-notes";
      this.setState({loader:true})

      return axios.post(url, formData)
        .then(res => {
          if (res.data.status === "200"){
            toast.success("Upload successfully!");
            this.setState({loader:false})

          }
          else {
            toast.error("all fields are mandatory")
            this.setState({loader:false})

          }
          
        })
    }
   
  }
  update = () => {
    toast.configure();

    if (this.state.selectedNotes === '') {
      toast.error("Please selecta document to update");
    }
    else {
      var updateData = {
        "categoryId": this.state.selectedCategory,
        "subcategoryId": this.state.selectedSubCategory,
        "notesId": this.state.selectedNotes,
        "document": this.state.documentName,
        "encodedDocument": this.state.encodedDocument
      }
      const url = Api.API_URL + "update-notes";
      return axios.post(url, updateData)
        .then(res => {
          if (res.data.status === "200")
            toast.success("Update successfully!");
          else
            toast.error(res.data.message);
        })
    }
  }
  onChange(e) {
    let files = e.target.files
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (f) => {
      var str = f.target.result
      var array = str.split(",", 2);
      this.setState({
        documentName: files[0].name,
        encodedDocument: array[1]
      })
    }
  }
  fetchPdf = (selectedOrderbyValue) => {
    var pagination =
    {
      "pageNum": 1,
      "numPerPage": 10,
      "categoryId": this.state.selectedCategory,
      "documentType": this.state.documentType,
    }
    var url = Api.API_URL+'subcategory-wise-notes-list/' + this.state.selectedSubCategory;
    axios.post(url, pagination)
      .then(res => {
        if (res.data.status === "200") {
          const data = res.data.result;
          this.setState({data})
          let selectedDocs = [];
          res.data.result.map(doc => {
            if (selectedOrderbyValue.includes(doc.documentType)) {
              selectedDocs = selectedDocs.concat(doc);
            }
          })
          this.setState({
            data: selectedDocs
          })
          let notesFromApi = res.data.result.map(doc => {
            return { value: doc.notesId, display: doc.document };
          });
          this.setState({
            notes: [
              {
                value: "",
                display: "Select document"
              }
            ].concat(notesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }
  updateDocument(newData) {
    var parameters = {
      "categoryId": this.state.selectedCategory,
      "subcategoryId": this.state.selectedSubCategory,
      "document": this.state.documentName,
      "encodedDocument": this.state.encodedDocument,
     
    }
    axios.post(Api.API_URL + 'update-notes', parameters)
      .then(res => {
      
      })
  }
  setDocumentType = (e) => {
    this.setState({
      documentType: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select document type"
          : ""
    })
 
    // this.setShowtable();
  }

  setShowtable = () => {
    this.setState({
      isShowTable: true
    })
  }
  setshowDocuments = (e) => {
    this.setState({
      showDocuments: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select order by"
          : ""
    })
   
    this.setShowtable();
    this.fetchPdf(e.target.value);
  }
  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api.API_URL +'module-wise-list-category',pagination)
    .then(response => {
  
      this.setState({categories: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })
  }
  render() {
    if(this.state.loader ===true){
      return <div className="text-center  marginloader" ><Loader
      type="Puff"
      color="#00e600"
      height={100}
      width={100}
      
       timeout={4000} //4 secs

   /></div> 
    }
    return (
      <React.Fragment>
         <h5 className="text-muted pl-5 pt-4 text-right">
                <Link to="/listnotes" className="dropdown-item"><span className="material-icons" >add</span> Notes List</Link>
              </h5>
        <h5 className="text-muted pl-5 pt-4">Notes</h5>
        <div className="container-fluid pt-3 pb-5 mb-3">
          <div className="row">
            <div className="col-md-12 pb-3">
              <div className="card">
                <div className="card-body">
                <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                  <label >Select Category</label>
                  <select
                    value={this.state.selectedCategory}
                    onChange={this.setCategory}
                    className="form-control">
                    {this.state.categories.map(cat => (
                      <option
                        key={cat.categoryId}
                        value={cat.categoryId}
                      >
                        {cat.category}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["selectedCategory"]}</span>
                  <br></br>
                  <label >Select Sub-category</label>
                  <select
                    value={this.state.selectedSubCategory}
                    onChange={this.setSubCategory}
                    className="form-control">
                    {this.state.subCat.map(cat => (
                      <option
                        key={cat.value}
                        value={cat.value}
                      >
                        {cat.display}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["selectedSubCategory"]}</span>
                  <br></br>
                  <h5>Title</h5>
                  <input 
                  type="text"
                  className="form-control"
                   onChange={this.onChangefortitle}
                   defaultValue={this.state.title}
                   ></input>
                  <br></br>
                  <h5>Description</h5>
                  <textarea 
                  type="text"
                  className="form-control" 
                  defaultValue={this.state.description}
                  onChange={this.onChangeford}
                  ></textarea>  
                  <br></br>
                  <h5>Upload Documents</h5>
                  <select
                    value={this.state.documentType}
                    onChange={this.setDocumentType}
                    className="form-control">
                    {this.state.type.map(show => (
                      <option
                        key={show.value}
                        value={show.value}
                      >
                        {show.display}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["documentType"]}</span>
                  <br></br>
                  <input type="file" name="file" onChange={(e) => this.onChange(e)} /><br></br>
                  <span style={{ color: "red" }}>{this.state.errors["documentName"]}</span><br></br>
                  <button type="submit" className=" btn primary mb-3 mt-3" onClick={this.upload}>Upload</button><br></br>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 pb-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div onSubmit={this.FormSubmit}>
                    <h5>Update Documents</h5>
                    
                    <select
                      value={this.state.selectedNotes}
                      onChange={this.setNotes}
                      className="form-control">
                      {this.state.notes.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}
                        </option>
                      ))}
                    </select>
                    <input type="file" name="file" className="pt-2" onChange={(f) => this.onChange(f)} /><br></br>
                    <button type="submit" className="btn primary mb-3 mt-3" onClick={this.update}>Update</button><br></br><br></br>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-12 pb-4 mb-4">
              {this.state.isShowTable && <MaterialTable
                title="Documents"
                columns={this.state.columns}
                data={this.state.data}
                editable={{
                }}
              />
              }
            </div>
          </div>
        </div>
        {/* </div> */}
        <Container />
        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </React.Fragment>

    )
  }
}





