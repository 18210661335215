import React, { Component } from 'react'
import 'date-fns';
// import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// import dateFormat from 'dateformat';

export default class TimePicker extends Component {

constructor(props) {
  super(props)

  this.state = {
    startTime: "10:00:00",
    endTime: "11:00:00"
  }
}


setStartTime = (time) => {
    this.setState({ startTime: time })
    console.log("start time: ", time)
  }


setEndTime = (time) => {
this.setState({ endTime: time })
}

  render() {
    return (
      <React.Fragment>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        {/* <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="yyyy/MM/dd"
          margin="normal"
          id="date-picker-inline"
          label="Date picker inline"
          name="startDate"
          value={this.state.startDate}
          onChange={this.handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> */}
        {/* <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="MM/dd/yyyy"
          name="startDate"
          value={this.state.startDate}
          onChange={this.handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> */}
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
        //   label="Time picker"
        //   maxDetail="second"
          value={this.state.startTime}
          onChange={this.setStartTime}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        /><br></br>
        {/* <KeyboardTimePicker
          margin="normal"
          id="time-picker1"
          label="Time picker"
          maxDetail="second"
          value={this.state.endTime}
          onChange={this.setEndTime}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        /> */}
      </Grid>
    </MuiPickersUtilsProvider>
      </React.Fragment>
    )
  }
}
