import React, { Component } from 'react'
import { Link } from "react-router-dom"
import './DropDown.css';

export default class DropdownExample extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <ul id="slide-out" className="pt-3 side-nav-light">
            <Link to="/category"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#34e5eb" }}>category</span> Category</li></Link><br></br>
            <Link to="/sub-category"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#9c34eb" }}>edit</span>SubCategory</li></Link><br></br>
            <Link to="/showtest"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#a2a832" }}>book_online</span>Tests</li></Link><br></br>

            <Link to="/showquestions"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#53eb34" }}>view_module</span>Questions</li></Link><br></br>
            <Link to="/listnotes"><li className="text-muted pl-3 text-menu "><span className="material-icons" style={{ color: "#eb34b7" }}>note_add</span>Notes</li></Link><br></br>
            <Link to="/addadvertisement"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#42a832" }}>panorama</span>Advertisement</li></Link><br></br>
            <Link to="/addbooks"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#b35465" }}> menu_book</span> Books</li></Link><br></br>
            <Link to="/addjobs"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#4c1269" }}>work</span> Jobs</li></Link><br></br>
            <Link to="/package"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#4c1269" }}>work</span> Package</li></Link><br></br>

            <Link to="/listinstitute"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#241da1" }}>account_balance</span> Institute</li></Link><br></br>
            <Link to="/getfeedback"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#a2b570" }}>thumb_up_alt</span>  Feedback</li></Link><br></br>
            <Link to="/listvideo"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#000000" }}>play_circle_outline</span> Videos</li></Link><br></br>
            <Link to="/addaffairs"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#e39c2b" }}>pages</span>  Current-Affairs</li></Link><br></br>
            <Link to="/StudentList"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#2be356" }}>post_add</span>  Student-List</li></Link><br></br>
            <Link to="/subscription"><li className="text-muted pl-3 text-menu"><span className="material-icons" style={{ color: "#2be356" }}>view_module</span>  Subscription-List</li></Link><br></br>


            {/*<li className="nav-item dropdown pl-1 ">
            <a >
              <span className="material-icons" style={{ color: "#53eb34" }}>view_module</span>  Questions
            </a>
            <h6>
              <Link to="questions" className="dropdown-item text-muted "><span className="material-icons" >add</span>Add Questions</Link>
              <Link to="/showquestions" className="dropdown-item text-muted ml-3" >Show Questions</Link>
            </h6>
            </li>
             <li className="nav-item dropdown pl-1">
              <a  >
                <span className="material-icons" style={{ color: "#eb34b7" }}>note_add</span> Notes
            </a>
                <Link to="/notes" className="dropdown-item text-muted" ><span className="material-icons" >add</span>Add Notes</Link>
                <Link to="/listnotes" className="dropdown-item text-muted ml-3" >List Notes</Link>
            </li><br></br>
            <li className="nav-item dropdown pl-1">
              <a >
                <span className="material-icons" style={{ color: "#a2a832" }}>book_online</span> Test
            </a>
                <Link to="/addtest" className="dropdown-item text-muted" ><span className="material-icons" >add</span>Add Test</Link>
              <Link to="/showtest" className="dropdown-item text-muted ml-3" >Show Test</Link>
            </li><br></br>
          */}

            {/*<li className="nav-item dropdown pl-1">
              <a className="nav-link dropdown-toggle text-muted" href="" id="navbarDropdown" role="button" data-toggle="dropdown" >
                <span className="material-icons" style={{ color: "#42a832" }}>panorama</span> Advertisement
              </a>
              <div className="dropdown-menu mr-5" aria-labelledby="navbarDropdown">
                <Link to="/addadvertisement" className="dropdown-item text-muted">Add Advertisement</Link>
              </div>
            </li><br></br>
            <li className="nav-item dropdown pl-1">
              <a className="nav-link dropdown-toggle text-muted" href="" id="navbarDropdown" role="button" data-toggle="dropdown" >
                <span className="material-icons" style={{ color: "#b35465" }}> menu_book</span> Books
              </a>
              <div className="dropdown-menu mr-5" aria-labelledby="navbarDropdown">
                <Link to="/addbooks" className="dropdown-item text-muted">Add Books</Link>
              </div>
            </li><br></br>
            <li className="nav-item dropdown pl-1">
              <a className="nav-link dropdown-toggle text-muted" href="" id="navbarDropdown" role="button" data-toggle="dropdown" >
                <span className="material-icons" style={{ color: "#4c1269" }}>work</span> Jobs
              </a>
              <div className="dropdown-menu mr-5" aria-labelledby="navbarDropdown">
                <Link to="/addjobs" className="dropdown-item text-muted">Add Jobs</Link>
              </div>
            </li><br></br>*/}

          {/*
            <li className="nav-item dropdown pl-1">
              <a>
                <span className="material-icons" style={{ color: "#241da1" }}>account_balance</span> Institute
              </a>
              <Link to="/addinstitute" className="dropdown-item text-muted"><span className="material-icons" >add</span>Add Institute</Link>
              <Link to="/listinstitute" className="dropdown-item text-muted ml-3">List Institute</Link>
            </li><br></br>
            <li className="nav-item dropdown pl-1">
              <a className="nav-link dropdown-toggle text-muted" href="" id="navbarDropdown" role="button" data-toggle="dropdown" >
                <span className="material-icons" style={{ color: "#a2b570" }}>thumb_up_alt</span>  Feedback
              </a>
              <div className="dropdown-menu mr-5" aria-labelledby="navbarDropdown">
                <Link to="/getfeedback" className="dropdown-item text-muted">Get Feedback</Link>
              </div>
            </li><br></br>
            <li className="nav-item dropdown pl-1">
              <a  >
                <span className="material-icons" style={{ color: "#000000" }}>play_circle_outline</span> Videos
              </a>
              <Link to="/addvideo" className="dropdown-item text-muted"><span className="material-icons" >add</span>Add Videos</Link>
              <Link to="/listvideo" className="dropdown-item text-muted ml-3">List Videos</Link>
            </li><br></br>
            <li className="nav-item dropdown pl-1">
              <a className="nav-link dropdown-toggle text-muted" href="" id="navbarDropdown" role="button" data-toggle="dropdown" >
                <span className="material-icons" style={{ color: "#e39c2b" }}>pages</span>  Current-Affairs
              </a>
              <div className="dropdown-menu mr-5" aria-labelledby="navbarDropdown">
                <Link to="/addaffairs" className="dropdown-item text-muted">Add Affairs</Link>
              </div>
            </li><br></br>
            <li className="nav-item dropdown pl-1">
              <a className="nav-link dropdown-toggle text-muted" href="" id="navbarDropdown" role="button" data-toggle="dropdown" >
                <span className="material-icons" style={{ color: "#2be356" }}>post_add</span>  Student-List
              </a>
              <div className="dropdown-menu mr-5" aria-labelledby="navbarDropdown">
                <Link to="/StudentList" className="dropdown-item text-muted">Student-List</Link>
              </div>
            </li><br></br>*/}
          </ul>
        </div>
      </React.Fragment>
    )
  }
}
