import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';
import Api from '../Constant/Api'

export default class ShowJobs extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            columns: [
                { title: 'Post Name', field: 'postName', initialEditValue: '' },
                { title: 'Post', field: 'post', initialEditValue: '' },
                { title: 'Document', field: 'document', initialEditValue: '' },
               
              ],
              data: [],
        }
    }
    
    componentDidMount(){
        var pagination = {
            "pageNum":1,
            "numPerPage":10
        }
        var url= Api.API_URL + 'jobs-list';
        axios.post(url,pagination)
        .then(res => {
            // console.lobookUrlg(response)
            this.setState({ data: res.data.result })
          })
          .catch(error => {
            console.log(error)
          })
    }

    render() {
        return (
              <React.Fragment>
                <h5 className="text-muted pl-5 pt-4">Show Jobs</h5>
                <div className="container pt-3 mb-3 pb-5 mb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <MaterialTable
                                title="Show Jobs"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowAdd: (newData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("From onRowAdd")
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const add = [...prevState.add];
                                    //         add.push(newData);
                                    //         {this.addCategoryData(newData)}
                                    //         return { ...prevState, add };
                                    //       });
                                    //       // { this.addCategoryData(newData) }
                                    //     }, 600);
                                    //   }),
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("Resolve: ", resolve);
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         // console.log("oldData: ", oldData);
                                    //         // console.log("newData: ", newData);
                                    //         { this.updateCategoryList(newData) }
                                    //         this.setState((prevState) => {
                                    //           // console.log("prevState: ", prevState);
                                    //           const data = [...prevState.data];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    // onRowDelete: (oldData) =>
                                    //   new Promise((resolve) => {
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const data = [...prevState.data];
                                    //         data.splice(data.indexOf(oldData), 1);
                                    //         return { ...prevState, data };
                                    //       });

                                    //     { this.DeleteCategoryList(oldData) }
                                    //     }, 600);
                                    //   }),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
