import MaterialTable from 'material-table';
import axios from 'axios';
import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";
import Api from '../components/Constant/Api';
import Loader from 'react-loader-spinner';
import Api_url from "../components/Constant/Api";


class Subcategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      selectedCategory: "",
      validationError: "",
      subcategory: "",
      columns: [
        { title: '', field: '', initialEditValue: '' },
        { title: '', field: '', initialEditValue: '' },
        { title: <h5><b>Sub-Category</b></h5>, field: 'subcatName', initialEditValue: '' },
      ],
      data: [],
      fields: {},
      errors: {},
      loader:''
    }

    // this.setCategory = this.setCategory.bind(this);
    // this.fetchSubcategory = this.fetchSubcategory.bind(this);

  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    if (this.state.selectedCategory === '') {
      formIsValid = false;
      errors["selectedCategory"] = "*Please Select Category";
    }
    if (this.state.subcategory === '') {
      formIsValid = false;
      errors["subcategory"] = "*Please Enter Sub-category";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  componentDidMount() {
    this.subcategoryList()
  }


  subcategoryList = () => {
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10
    }
    axios.post(Api.API_URL + 'subcategory-list', pagination)
      .then(res => {
        this.setState({
          data: res.data.result
        })
        console.log(res)
      })
  }

  listCategory = () => {
    axios.get(Api.API_URL + 'list-category')
      .then(res => {
        if (res.data.status === "200") {
          let categoriesFromApi = res.data.result.map(cat => {
            return { value: cat.categoryId, display: cat.category };
          });
          this.setState({
            categories: [
              {
                value: "",
                display: "Select Category"
              }
            ].concat(categoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });

  }

  setSubCategory = (e) => {

    this.setState({ [e.target.name]: e.target.value });

  }

  addSubCategory(e) {
    e.preventDefault();
this.setState({loader:true})
    if (this.handleValidation()) {
      var requiredData = {
        "categoryId": this.state.selectedCategory,
        "subcatName": this.state.subcategory
      }

      axios.post(Api.API_URL + 'add-subcategory', requiredData)
        .then(res => {
          if (res.data.status === "200") {
            toast.success(res.data.message)
            this.setState({loader:false})

            this.fetchSubcategory(this.state.selectedCategory);
          }
        })
    }
    else {
      this.setState({loader:false})

      toast.error("all fields are mandatory")
    }
  }

  fetchSubcategory(selectedCategory) {
    console.log();
    var url = Api.API_URL + 'category-wise-subcategory/';
    axios.get(url + selectedCategory)
      .then(res => {
        this.setState({ data: res.data.result })
      })
  }


  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    this.fetchSubcategory(e.target.value);
  }

  updateSubcat(newData) {
    var passdata = {
      "categoryId": newData.categoryId,
      "subcategoryId": newData.subcategoryId,
      "subcatName": newData.subcatName
    }
    axios.post(Api.API_URL + 'update-subcategory', passdata)
      .then(res => {
        if(res.data.status==="200"){
        toast.success(res.data.message);
      }else{
        toast.error(res.data.message);

      }

      })
      .catch(error => {
        toast.error(error)
      })
  }

  deleteSubcategory(oldData) {
    var deletedata = {
      "categoryId": this.state.selectedCategory,
      "subcatName": oldData.subcatName
    }
    var url = Api.API_URL + 'delete-subcategory/' + oldData.subcategoryId;
    axios.delete(url, deletedata)
      .then(res => {
        if (res.data.status === "200") {
          toast.success(res.data.message);
        }
        else {
          toast.error(res.data.message)
        }
      })
      .catch(error => {
        toast.error(error)
      })
  }
  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api_url.API_URL +'module-wise-list-category',pagination)
    .then(response => {
      this.setState({categories: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })
  }
  render() {
    if(this.state.loader ===true){
      return <div className="text-center  marginloader" ><Loader
      type="Puff"
      color="#00e600"
      height={100}
      width={100}
      
     //  timeout={3000} //3 secs

   /></div> 
    }
    return (
      <React.Fragment>
        <div className="container-fluid pt-3 mb-5 pb-5">

          <div className="row">
            <div className="col-md-6 pb-3">
              <div className="card">
                <div className="card-body">
                <h5 className="text-muted">Sub-category</h5><br></br>

                <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                  <label>Choose Category</label>
                  <select
                    value={this.state.selectedCategory}
                    onChange={this.setCategory} value={this.state.selectedCategory}
                    className="form-control">
                    {this.state.categories.map(team => (
                      <option
                        key={team.categoryId}
                        value={team.categoryId}
                      >
                        {team.category}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["selectedCategory"]}</span><br></br>

                  <label>Enter Sub-category</label>
                  <input type="text" name="subcategory" placeholder="Enter subcategory" className="form-control"
                    onChange={this.setSubCategory} value={this.state.subcategory}
                  />
                  <span style={{ color: "red" }}>{this.state.errors["subcategory"]}</span><br></br><hr></hr>

                  <button type="button" className="btn primary ml-5 "
                    onClick={(e) => this.addSubCategory(e)}>Add Subcategory</button>

                </div>
              </div>
            </div>

            <div className="col-md-6">
              <MaterialTable
                title="Sub-Category"
                columns={this.state.columns}
                data={this.state.data}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          { this.updateSubcat(newData) }
                          this.setState((prevState) => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        this.setState((prevState) => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                        { this.deleteSubcategory(oldData) }
                      }, 600);
                    }),
                }}
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </React.Fragment>
    )
  }
}
export default Subcategory
