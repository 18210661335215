import React, { Component } from 'react'
import axios from 'axios';
import MaterialTable from 'material-table';
import Api_url from '../Constant/Api'

export default class ListAdvertisement extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            columns: [
                {title:'Image',field: <div><img src="assets/preview.png"/></div>, initialEditValue:<div><img src="assets/preview.png"/></div> },
                { title: 'Image Name', field: <div><img src="assets/preview.png"/></div>, initialEditValue: <div><img src="assets/preview.png"/></div> },
                { title: '', field: '', initialEditValue: '' },
                { title: 'Created At', field: 'createdAt', initialEditValue: '' },
                
              ],
              data: [],
              allImages:[],
              show: false
        }
    }
    
    componentDidMount(){
        // this.fetchImages()
        var pagination = {
            "pageNum":1,
            "numPerPage":10
        }
        var url= Api_url.API_URL + 'list-advertisement';
        console.log("utl",url)
        axios.get(url,pagination)
        .then(res => {
            // console.lobookUrlg("resc",res)
            this.setState({ data: res.data.result })
            console.log("res",this.state.data)
          })
          .catch(error => {
            console.log(error)
          })

    }

    fetchImages = () =>{

     let imagesFromApi = this.state.data.map((Image)=>{
        axios.get(Api_url.API_URL + "get-image/" + Image.image + "/" )
        .then(res => {
              console.log("abc",(res.data));
            return {xyz: res.data}
        })
    })

    //  imagesFromApi.map((img)=> {
    //      console.log(img.xyz);
    //  })
    this.setState({
        allImages: imagesFromApi,
        show: true
    })
    }
    Deleteimage = (oldData) =>{
        // this.state.data.map((delete)=>{

        // })
        axios.delete(Api_url.API_URL + "delete-notes",)
        .then(res=>{
            console.log("delete",res)
        })
    }

    render() {
        return (
              <React.Fragment>
                <h5 className="text-muted pl-5 pt-4">List Advertisement</h5>
                <div className="container pt-3 mb-3 pb-5 mb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <MaterialTable
                                title="List Advertisement"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowAdd: (newData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("From onRowAdd")
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const add = [...prevState.add];
                                    //         add.push(newData);
                                    //         {this.addCategoryData(newData)}
                                    //         return { ...prevState, add };
                                    //       });
                                    //       // { this.addCategoryData(newData) }
                                    //     }, 600);
                                    //   }),
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("Resolve: ", resolve);
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         // console.log("oldData: ", oldData);
                                    //         // console.log("newData: ", newData);
                                    //         { this.updateCategoryList(newData) }
                                    //         this.setState((prevState) => {
                                    //           // console.log("prevState: ", prevState);
                                    //           const data = [...prevState.data];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    onRowDelete: (oldData) =>
                                      new Promise((resolve) => {
                                        setTimeout(() => {
                                          resolve();
                                          this.setState((prevState) => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                          });

                                        { this.Deleteimage(oldData) }
                                        }, 600);
                                      }),
                                }}
                            />
                             
                             {/* { this.state.show && this.state.allImages.map((abc) => {
                                  <Image source={abc.xyz} />
                                // console.log("akash: ");
                             })} */}
                            
                            { this.state.show && <img src={this.state.allImages[0].xyz} /> }
                             
                            {/* <button className =" btn primary " type="button" onClick={this.fetchImages} >add</button> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
