export default function() {
  return [
    {
      title: "Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Category",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      //go to views then BlogPost.js
      to: "/category",//blog-posts
    },
    {
      title: "Sub Category",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/sub-category",
    },
    {
      title: "Questions",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/questions",
    },
    {
      title: "Notes",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/notes",
    },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Test",
    //   htmlBefore: '<i class="material-icons">add_to_queue</i> ',
    //   to: "/test",
      
    // },
    // {
    //   title:"Addtest",
    //   htmlBefore:'<i class="material-icons">add_to_queue</i>',
    //   to: "/addtest"
    // } ,
    // {
    //   title:"",
    //   htmlBefore:'<i class="material-icons">add_to_queue</i>',
    //   to:"/showquestions"
    // } 
    
  ];
  
}
