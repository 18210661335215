import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import Api from '../Constant/Api';
import MaterialTable from 'material-table';
import Loader from 'react-loader-spinner';


export default class AddAffairs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            year: '',
            month: '',
            documentName: '',
            title:'',
            description:'',
            title: '',
            fields: {},
            errors: {},
            loader:'',
            columns: [
                { title: <h5><b>Title</b></h5>, field: 'title', initialEditValue: '' },
                { title: <h5><b>Description</b></h5>, field: 'description', initialEditValue: '' },
                { title: <h5><b>Year</b></h5>, field: 'year', initialEditValue: '' },
                
                { title: <h5><b>Month</b></h5>, field: 'month', initialEditValue: '' },
                { title: <h5><b>Documents</b></h5>, field: 'pdfUrl', initialEditValue: '' },

            ],
            data: [],
        }
    }
    downloadFile(e) {
        window.location.href = Api.PDF_PATH+e.target.value+"/"
      }
    componentDidMount() {
        var pagination = {
            "pageNum": 1,
            "numPerPage": 10
        }
        var url = Api.API_URL + 'current-affairs-list';
        axios.post(url, pagination)
            .then(res => {
                for(var i=0; i<res.data.result.length; i++){
                    var pdfUrl=<button onClick={this.downloadFile} value={res.data.result[i].document}>download</button>
                    res.data.result[i].pdfUrl=pdfUrl;
                }
                this.setState({ data: res.data.result })
            })
            .catch(error => {
                console.log(error)
            })
    }
  

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        if (this.state.year === '') {
            formIsValid = false;
            errors["year"] = "*Please Enter Year";
        }
        if (this.state.month === '') {
            formIsValid = false;
            errors["month"] = "*Please Enter month";
        }
        if (this.state.documentName === '') {
            formIsValid = false;
            errors["documentName"] = "*Please Choose Doc";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    setAffairsInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        
    }
    addAffairsHandler(e) {
        let files = e.target.files
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (f) => {
            var str = f.target.result
            var array = str.split(",", 2);

            this.setState({
                documentName: files[0].name,
                encodedDocument: array[1],
            })
            
        }
    }
    addAffairs = (e) => {
        this.setState({loader:true})

        e.preventDefault();

        if (this.handleValidation()) {
            var affairsData = {
                "year": this.state.year,
                "month": this.state.month,
                "title":this.state.title,
                "description":this.state.description,  
                "document": this.state.documentName,
                "encodedDocument": this.state.encodedDocument
            }

            const url = Api.API_URL + "add-current-affairs";

            return axios.post(url, affairsData)
                .then(res => {
                    // console.warn("result", res);
                    if (res.data.status === "200"){
                        this.setState({loader:false})
                        toast.success("Upload successfully!");
                        this.componentDidMount()
                    }
                    else{
                        this.setState({loader:false})

                        toast.error(res.data.message);
                    }
                })
        }
        else {
            toast.error("all fields are mandatory")
        }
    }
    deleteCurrentAffairs = (oldData) =>{
        axios.delete(Api.API_URL + 'delete-current-affairs/' + oldData.currentAffairsId)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error(res.data.message)
                }

            })
            .catch(error => {
                toast.error(error)
            })
    }

    render() {
        if(this.state.loader ===true){
            return <div className="text-center  marginloader" ><Loader
            type="Puff"
            color="#00e600"
            height={100}
            width={100}
            
           //  timeout={3000} //3 secs
      
         /></div> 
          }
        return (
            <React.Fragment>
                <h5 className="text-muted pl-5 pt-4">Add Affairs</h5>
                <div className="container-fluid pt-3">
                    <div className="row">
                        <div className="col-md-6 pb-3">
                            <div className="card">
                                <div className="card-body">
                                <label>title</label>
                                    <input type="text" className="form-control" name="title" placeholder="title"
                                        onChange={this.setAffairsInput}
                                    
                                    />
                                     <label>Description</label>
                                    <textarea type="text" className="form-control" name="description" placeholder="description"
                                        onChange={this.setAffairsInput}
                                    
                                    />
                                    <label>Year</label>
                                    <input type="number" min="0" className="form-control" name="year" placeholder="year"
                                        onChange={this.setAffairsInput}
                                    
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["year"]}</span><br></br>

                                    <label>Month</label>
                                    <input type="number" min="0" className="form-control" name="month" placeholder="month"
                                        onChange={this.setAffairsInput}
                                    // onChange={this.handleChange.bind(this, "month")} value={this.state.fields["month"]}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["month"]}</span><br></br>

                                    <label>Document</label><br></br>
                                    <input type="file" name="file"
                                        onChange={(e) => this.addAffairsHandler(e)}
                                    // onChange={this.handleChange.bind(this, "file")} value={this.state.fields["file"]}
                                    />

                                    <span style={{ color: "red" }}>{this.state.errors["documentName"]}</span><br></br><hr></hr>

                                    <button type="button" className=" btn primary mb-3 mt-3" onClick={this.addAffairs}>Add Affairs</button><br></br>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pb-5 mb-5">
                            <MaterialTable
                                title="List Affairs"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowAdd: (newData) =>
                                    //   new Promise((resolve) => {
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const add = [...prevState.add];
                                    //         add.push(newData);
                                    //         {this.addCategoryData(newData)}
                                    //         return { ...prevState, add };
                                    //       });
                                    //       // { this.addCategoryData(newData) }
                                    //     }, 600);
                                    //   }),
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         { this.updateCategoryList(newData) }
                                    //         this.setState((prevState) => {
                                    //           const data = [...prevState.data];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    onRowDelete: (oldData) =>
                                      new Promise((resolve) => {
                                        setTimeout(() => {
                                          resolve();
                                          this.setState((prevState) => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                          });

                                        { this.deleteCurrentAffairs(oldData) }
                                        }, 600);
                                      }),
                                }}
                            />

                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        )
    }
}
