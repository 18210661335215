import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import Api from '../Constant/Api';
import MaterialTable from 'material-table';
import Loader from 'react-loader-spinner';


export default class AddBooks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            url: '',
            booksList: [],
            fields: {},
            errors: {},
            loader:'',
            columns: [
                { title: <h5><b>Book Name</b></h5>, field: 'bookName', initialEditValue: '' },
                { title: '', field: '', initialEditValue: '' },
                { title: <h5><b>Book Url</b></h5>, field: 'bookUrl', initialEditValue: '' },

            ],
            data: [],
        }
    }

    componentDidMount() {
        var pagination = {
            "pageNum": 1,
            "numPerPage": 10
        }
        axios.post(Api.API_URL + 'books-list', pagination)
            .then(res => {
                if (res.data.status === "200") {
                    // toast.success(res.data.message)
                    this.setState({
                        data: res.data.result
                    })
                }
                else {
                    toast.error(res.data.message)
                }

            })
            .catch(error => {
                toast.error(error)
            })
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (this.state.name === '') {
            formIsValid = false;
            errors["name"] = "*Please Enter Book Name";
        }

        if (this.state.url === '') {
            formIsValid = false;
            errors["url"] = "*Please Enter Url";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    books = (e) => {

        this.setState({ [e.target.name]: e.target.value });

    }

    addbooks = (e) => {
        this.setState({loader:true})
        //  this.setState({
        //     booksList: this.state.booksList.concat({ "name": this.state.name, "url": this.state.url})
        // })
        e.preventDefault();

        if (this.handleValidation()) {
            // console.log("booklist", this.state.booksList)

            var formData = {
                "bookName": this.state.name, 
                "bookUrl": this.state.url 
            }

            const url = Api.API_URL + "add-books";

            return axios.post(url, formData)
                .then(res => {
                    console.warn("result", res);
                    if (res.data.status === "200") {
                        toast.success("Upload successfully!");
                        this.setState({loader:false})

                        this.componentDidMount()
                    }
                    else {
                        toast.error(res.data.message);
                        this.setState({loader:false})

                    }
                })
        }
        else {
            toast.error("all fields are mandetory");
            this.setState({loader:false})

        }
    }

    deleteBook = (oldData) => {
        axios.delete(Api.API_URL + 'delete-book/' + oldData.booksId)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error(res.data.message)
                }
    
            })
            .catch(error => {
                toast.error(error)
            })
    
    }
    render() {
        if(this.state.loader ===true){
            return <div className="text-center  marginloader" ><Loader
            type="Puff"
            color="#00e600"
            height={100}
            width={100}
            
           //  timeout={3000} //3 secs
      
         /></div> 
          }
        return (
            <React.Fragment>
                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-md-6 pb-3">
                            <div className="card">
                                <div className="card-body">
                                    <label>Add Book</label>
                                    <input type="text" placeholder="Enter book name" name="name" className="form-control" onChange={this.books} />
                                    <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
                                    <br></br>
                                    <label>Add Url</label>
                                    <input type="text" placeholder="Enter url" name="url" className="form-control" onChange={this.books} />
                                    <span style={{ color: "red" }}>{this.state.errors["url"]}</span>
                                    <br></br>

                                    <button type="button" className="btn primary" onClick={this.addbooks}>Add Book</button>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5 pb-5">


                            <MaterialTable
                                title="Show Books"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowAdd: (newData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("From onRowAdd")
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const add = [...prevState.add];
                                    //         add.push(newData);
                                    //         {this.addCategoryData(newData)}
                                    //         return { ...prevState, add };
                                    //       });
                                    //       // { this.addCategoryData(newData) }
                                    //     }, 600);
                                    //   }),
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("Resolve: ", resolve);
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         // console.log("oldData: ", oldData);
                                    //         // console.log("newData: ", newData);
                                    //         { this.updateCategoryList(newData) }
                                    //         this.setState((prevState) => {
                                    //           // console.log("prevState: ", prevState);
                                    //           const data = [...prevState.data];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    // onRowDelete: (oldData) =>
                                    //   new Promise((resolve) => {
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const data = [...prevState.data];
                                    //         data.splice(data.indexOf(oldData), 1);
                                    //         return { ...prevState, data };
                                    //       });

                                    //     { this.DeleteCategoryList(oldData) }
                                    //     }, 600);
                                    //   }),
                                    onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        this.setState((prevState) => {

                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                        { this.deleteBook(oldData) }
                      }, 600);
                    }),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        )
    }
}
