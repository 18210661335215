
// import React from "react";
import { Container } from "shards-react";
// import DatePicker from "./DatePicker"
// import TimePicker from "./TimePicker"
// import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import Timekeeper from 'react-timekeeper';
import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Grid from '@material-ui/core/Grid';
import {Link } from "react-router-dom"
import Loader from 'react-loader-spinner';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dateFormat from 'dateformat';
import Api from '../Constant/Api'

export default class AddTest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      selectedCategory: "",
      selectedSubCategory: "",
      validationError: "",
      subcategory: "",
      subCat: [],
      testName: "",
      testType: "",
      price:'',
      startDate: new Date("2020-01-01"),
      startDate1: "",
      startTime: "",
      endTime: "",
      fields: {},
      errors: {},
      loader:'',
      display: false
    }
    // this.setCategory = this.setCategory.bind(this);
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (this.state.selectedCategory === '') {
      formIsValid = false;
      errors["selectedCategory"] = "*Please Select Category";
    }
    if (this.state.selectedSubCategory === '') {
      formIsValid = false;
      errors["selectedSubCategory"] = "*Please Select Sub-category";
    }
    if (this.state.testType === '') {
      formIsValid = false;
      errors["testType"] = "*Please Choose Test Type";
    }
    if (this.state.testName === '') {
      formIsValid = false;
      errors["testName"] = "*Please Enter TestName";
    }
    // if (this.state.startDate1 === '') {
    //   formIsValid = false;
    //   errors["startDate1"] = "*Please Enter StartDate";
    // }
    // if (this.state.startTime === '') {
    //   formIsValid = false;
    //   errors["startTime"] = "*Please Enter StartTime";
    // }
    // if (this.state.endTime === '') {
    //   formIsValid = false;
    //   errors["endTime"] = "*Please Enter EndTime";
    // }

    this.setState({ errors: errors });
    return formIsValid;
  }
  //   handleChange(field, e){        
  //     let fields = this.state.fields;
  //     fields[field] = e.target.value;        
  //     this.setState({fields});

  // }

  // componentDidMount() {
  //   // console.log(dateFormat(this.state.startDate,"yyyy-mm-dd"));
  //   axios.get(Api.API_URL + 'list-category')
  //     .then(res => {
  //       if (res.data.status === "200") {
  //         let categoriesFromApi = res.data.result.map(cat => {
  //           return { value: cat.categoryId, display: cat.category };
  //         });
  //         this.setState({
  //           categories: [
  //             {
  //               value: "",
  //               display: "Select Category"
  //             }
  //           ].concat(categoriesFromApi)
  //         });
  //       }
  //       else {
  //         toast.error(res.data.message);
  //       }
  //     })
  //     .catch(error => {
  //       toast.error(error);
  //     });
  // }

  setSubCategory = (e) => {

    this.setState({ [e.target.name]: e.target.value });

  }

  setParameter = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  fetchSubcategory(selectedCategory) {

    var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
    axios.get(url)
      .then(res => {
        if (res.data.status === "200") {
          console.log("result array: ", res.data.result);
          let subCategoriesFromApi = res.data.result.map(cat => {
            return { value: cat.subcategoryId, display: cat.subcatName };
          });
          console.log("subcategories from API: ", subCategoriesFromApi);
          this.setState({
            subCat: [
              {
                value: "",
                display: "Select Sub-Category"
              }
            ].concat(subCategoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });

  }

  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    // console.log("setcategory",e.target.value)
    this.fetchSubcategory(e.target.value);
  }

  setSubCategory = (e) => {
    this.setState({
      selectedSubCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite sub category"
          : ""
    })
    // console.log("set sub category:",e.target.value)

  }

  setTestType = (e) => {
    this.setState({
      testType: e.target.value
    });

    if (e.target.value === "online") {

      this.setState({
        display: true
      })
    }
    if (e.target.value === "Offline" || e.target.value === "Free") {

      this.setState({
        display: false
      })
    }
  }

  setStartTime = (time) => {
    this.setState({ startTime: time })
  }


  setEndTime = (endTime) => {
    this.setState({ endTime })
    console.log("endTime:", this.state.endTime)
  }


  addTest = (e) => {
    toast.configure();

    e.preventDefault();
this.setState({loader:true})    
    if (this.handleValidation()) {
      if(this.state.testType==="online"){ 
        var passdata = {
          // categoryId: parseInt(this.state.selectedCategory),
          subcategoryId:parseInt( this.state.selectedSubCategory),
          "testName": this.state.testName,
          "testType": this.state.testType,
          "testDate": this.state.startDate1,
          "startTime": this.state.startTime.formatted24,
          "endTime": this.state.endTime.formatted24,
          price:this.state.price
        }
      }
      else{
        var passdata = {
          // categoryId: parseInt(this.state.selectedCategory),
          subcategoryId:parseInt( this.state.selectedSubCategory),
          "testName": this.state.testName,
          "testType": this.state.testType,
          "price":this.state.price

          
        }
      }
    
      axios.post(Api.API_URL + 'add-subcategory-wise-test', passdata)
        .then(res => {
          if (res.data.status === "200") {
            toast.success("Added Sucessfully")
            this.setState({loader:false})
          }
          else {
            this.setState({loader:false})
            // toast.error(res.data.message)
          }
        })
    }
    else {
      this.setState({loader:false})
      toast.error("all fields are mandatory")
    }
  }

  handleDate = (date) => {
    this.setState({
      startDate1: dateFormat(date, "yyyy-mm-dd"),
      startDate: date
    })
  }
  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api.API_URL +'module-wise-list-category',pagination)
    .then(response => {
  
      this.setState({categories: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })
  }
  render() {
    if(this.state.loader ===true){
      return <div className="text-center  marginloader" ><Loader
      type="Puff"
      color="#00e600"
      height={100}
      width={100}
      
      // timeout={4000} //3 secs

   /></div> 
    }
    return (
      <React.Fragment>
         <h5 className="text-muted pl-5 pt-4 text-right">
          <Link to="/showtest" className="dropdown-item"><span className="material-icons" >add</span>Show Tests</Link>
        </h5>
        <Container />

        <h5 className="text-muted pl-5 pt-4">Add Test</h5>
        <div className="form-group">
          <div className="container-fluid pt-3 pl-3">
            <div className="row">
              <div className="col-md-6 pb-3">
                <div className="card">
                  <div className="card-body">
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                    <label className="text-muted">Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      // onChange={this.handleChange.bind(this, "selectedCategory")} value={this.state.fields["selectedCategory"]}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.category}
                        </option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>{this.state.errors["selectedCategory"]}</span><br></br>

                    <label className="text-muted">Select Sub-category</label>

                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      // onChange={this.handleChange.bind(this, "selectedSubCategory")} value={this.state.fields["selectedSubCategory"]}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>{this.state.errors["selectedSubCategory"]}</span><br></br>


                    {/* test name */}
                    <label className="text-muted">Test Name</label>
                    <input type="text" className="form-control" name="testName" placeholder="Enter Test Name"
                      onChange={this.setParameter}

                    />
                    <span style={{ color: "red" }}>{this.state.errors["testName"]}</span><br></br>

                    <label className="text-muted">Test Price</label>
                    <input type="number" min="0" className="form-control" name="price" placeholder="Enter price"
                      onChange={this.setParameter}

                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 pb-5 mb-5 ">
                <div className="card">
                  <div className="card-body">
                    {/* radio button */}
                    <label>Test Type</label>
                    {/* <input type="text" className="form-control" name="testType"  placeholder="Enter Test Type" onChange={this.setParameter}/> */}
                    <ul>
                      <li>
                        <label>
                          <input
                            type="radio"
                            value="online"
                            checked={this.state.testType === "online"}
                            onChange={this.setTestType}
                          // onChange={this.handleChange.bind(this, "testType")} value={this.state.fields["testType" === "Online"]}
                          />
                       &nbsp; Online
                      </label>

                      </li>
                      <li>
                        <label>
                          <input
                            type="radio"
                            value="offline"
                            checked={this.state.testType === "offline"}
                            onChange={this.setTestType}
                          />
                       &nbsp; Offline
                      </label>

                      </li>

                      <li>
                        <label>
                          <input
                            type="radio"
                            value="free"
                            checked={this.state.testType === "free"}
                            onChange={this.setTestType}
                          />
                       &nbsp; Free
                      </label><br></br>
                        <span style={{ color: "red" }}>{this.state.errors["testType"]}</span>
                      </li>
                    </ul><br></br>

                    { 
        (this.state.testType === "online")
          ? <div>   <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {this.state.display && <Grid >
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Select Date"
              format="MM/dd/yyyy"
              name="startDate"
              value={this.state.startDate}
              onChange={this.handleDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <span style={{ color: "red" }}>{this.state.errors["startDate1"]}</span>
          </Grid>
          }
        </MuiPickersUtilsProvider><br></br>


        {/* start time */}
        {this.state.display && <label>Start Time </label>}<br></br>

        {
          this.state.display &&              
          <Timekeeper
value={this.state.startTime}
onChange={this.setStartTime}
format="hh/mm/ss"

/>
        }
        <span style={{ color: "red" }}>{this.state.errors["startTime"]}</span>
        <br></br><br></br>
        {/* endTime */}
        {this.state.display &&
          <label>End Time</label>}<br></br>
        {
          this.state.display &&
          <Timekeeper
          value={this.state.endTime}
          onChange={this.setEndTime}
          
        />
        }
        <span style={{ color: "red" }}>{this.state.errors["endTime"]}</span>
        <br></br><hr></hr></div> 
          : <div>  </div> 
      }
                    <button className="btn primary mt-3 ml-5 " onClick={this.addTest}>Add Test</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </React.Fragment>
    )
  }
}