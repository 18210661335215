import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router';
import { BrowserRouter as Router } from "react-router-dom";
import './css/Main.css';
import './css/util.css';
import { ToastContainer, toast } from "react-toastify";
import Api from "../Constant/Api";
// import "react-toastify/dist/ReactToastify.css"
// import ReactNotification from 'react-notifications-component'
// import { ToastContainer } from 'react-toastify'; 
// import { store } from 'react-notifications-component';
// import Forgotpass from './Forgotpass'
// import UserActions from '../layout/MainNavbar/NavbarNav/UserActions'
  
class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }
  }
  // componentDidMount(){
  //   toast.success('this is toastas')
  // }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  gotToForgetPass = () => {
  
    this.props.history.push('/forgotpass');
  }

  loginsubmit = e => {
    e.preventDefault()
    
    var pagination = {
      email: this.state.email,
      password: this.state.password
    };
    // console.log(this.state);
    // var result;
    axios.post(Api.API_URL+'admin-login', pagination)
      .then(res => {
        if (res.data.status === "200") {
          localStorage.setItem('agriguruUser', JSON.stringify(res.data.result));
          toast.success(res.data.message);
          this.props.history.push('/blog-overview');
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
   

  render() {
    return (
      <Router>
        <React.Fragment>
          <div className="limiter">
            <div className="container-login100">
              <div className="wrap-login100">
              
                <form className="login100-form validate-form" onSubmit={this.loginsubmit}>
                <img
                // style={{,justifyContent:"center" } }
                src={require("../Login/images/tr logo.png")}
                className="center"
              />
                  <span className="login100-form-title p-b-26">
                    Welcome
                </span>
               
                  <span className="login100-form-title p-b-48">
                    <i className="zmdi zmdi-font" />
                  </span>
                  <div className="wrap-input100 ">
                    <input type="text" name="email" className="input100" onChange={this.changeHandler} />
                    <span className="focus-input100" data-placeholder="Email" />
                  </div>
                  <div className="wrap-input100">
                    <span className="btn-show-pass">
                      <i className="zmdi zmdi-eye" />
                    </span>
                    <input className="input100" type="password" name="password" onChange={this.changeHandler} />
                    <span className="focus-input100" data-placeholder="Password" />
                  </div>
                  <div className="container-login100-form-btn">
                    <div className="wrap-login100-form-btn">
                      <div className="login100-form-bgbtn" />
                      <button className="login100-form-btn" type="submit"  onClick={this.setData}>
                        Login
                   </button>                                   
                    </div>
                  </div>
                  <div className="text-center p-t-115">
                    {/* <span className="txt1">
                    Don’t have an account?
                </span> */}
                    <a className="txt2" href="" onClick={this.gotToForgetPass}>
                      Forgot Password ?
                  </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div id="dropDownSelect1" />
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </React.Fragment>
      </Router>
    )
  }
}
export default withRouter(Login);
