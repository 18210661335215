import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import QuestionList from '../../views/QuestionList';
import './Question.css';
import Api from '../Constant/Api';
import MaterialTable from 'material-table';
import { Link } from "react-router-dom";
export default class ShowQuestions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      selectedCategory: "",
      selectedSubCategory: "",
      selectedNotes: "",
      validationError: "",
      subcategory: "",
      subCat: [],
      selectedTest: "",
      data: [],
      questiondata: [],
      display: false,
      questionList: [],
      columns: [
        { title: <h5><b>Question</b></h5>, field: 'question', initialEditValue: '' },
        { title: <h5><b>A</b></h5>, field: 'objective[0].objective', initialEditValue: '' },
        { title: <h5><b>B</b></h5>, field: 'objective[1].objective', initialEditValue: '' },
        { title: <h5><b>C</b></h5>, field: 'objective[2].objective', initialEditValue: '' },
        { title: <h5><b>D</b></h5>, field: 'objective[3].objective', initialEditValue: '' },

      ],
      xyz: []
    }
  }


  componentDidMount() {
    // this.listCategory()
     this.questionList()
  }

  questionList = () => {
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10
    }
    axios.post(Api.API_URL + 'question-list', pagination)
      .then(res => {
        if (res.data.status === "200") {
          this.setState({
            questionList: res.data.result,

          })
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }

  listCategory = () => {
    axios.get(Api.API_URL + 'list-category')
      .then(res => {
        if (res.data.status === "200") {
          let categoriesFromApi = res.data.result.map(cat => {
            return { value: cat.categoryId, display: cat.category };
          });
          this.setState({
            categories: [
              {
                value: "",
                display: "Select Category"
              }
            ].concat(categoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }

  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    // console.log("setcategory",e.target.value)
    this.fetchSubcategory(e.target.value);
  }


  fetchSubcategory(selectedCategory) {
    // console.log("fetchsub: ", selectedCategory)
    if (selectedCategory !== '') {
      var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
      axios.get(url)
        .then(res => {
          if (res.data.status === "200") {
            // console.log("result array: ", res.data.result);
            let subCategoriesFromApi = res.data.result.map(cat => {
              return { value: cat.subcategoryId, display: cat.subcatName };
            });
            console.log("subcategories from API: ", subCategoriesFromApi);
            this.setState({
              subCat: [
                {
                  value: "",
                  display: "Select Sub-Category"
                }
              ].concat(subCategoriesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }


  setSubCategory = (e) => {
    this.setState({
      selectedSubCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite sub category"
          : ""
    })
    // console.log("set sub category:",e.target.value)
    this.ShowTest(e.target.value)
  }

  ShowTest(selectedSubCategory) {
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10
    }
    if (selectedSubCategory !== '') {
      var url = Api.API_URL + 'subcategory-wise-test?subcategoryId=' + selectedSubCategory;
      axios.post(url, pagination)
        .then(res => {
          if (res.data.status === "200") {
            let testformapi = res.data.result.map(test => {
              return { value: test.testId, display: test.testName };
            });
            this.setState({
              data: [
                {
                  value: "",
                  display: "Select Test Id"
                }
              ].concat(testformapi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }

  }

  setTestId = (e) => {
    this.setState({
      display: true,
      selectedTest: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must Test Id"
          : ""
    })
    console.log("Test Id:", e.target.value);

    this.showQuestion(e.target.value)
  }

  // addquestion(){
  //   var passobject = {
  //     "testId":this.state.selectedTest,
  //     "question":this.state.question,
  //     "objective":this.state.objective
  //   }
  //   console.log("addQuestion",passobject)
  // }
  showQuestion = (selectedTest) => {
    this.setState({selectedTest:selectedTest})
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10
    }
    var url = Api.API_URL + "test-wise-question?testId=" + selectedTest;
    axios.get(url, pagination)
      .then(res => {
        const questiondata = res.data.result
        var xyz = [];
        res.data.result.map((Question, index) => {
          xyz = xyz.concat({"question":Question.question, "questionId": Question.questionId, "objective": Question.objective});
        })

        this.setState({
          questionList: xyz,
          questionList: questiondata
        })
      })
  }
  displayQuestions = () => {
    return this.state.questiondata.map((question, index) => {    
    })
  }
  setDisplay = () => {
    this.setState({
      display: true
    })
  }

  deleteTest = (oldData) => {
    axios.delete(Api.API_URL + "delete-question/" + oldData.questionId)
    .then(res => {
      if(res.data.status === "200") {
        toast.success(res.data.message);
      
      }
      else {
        toast.error(res.data.message);
      }
      this.showQuestion(this.state.selectedTest)
    })
  }
  
  updateQuestion = (oldData) => {
   
    var passdata={
      questionId:oldData.questionId,
      testId:oldData.testId,
      question:oldData.question,
     
    }
    axios.delete(Api.API_URL + "update-question", passdata)
    .then(res => {
      if(res.data.status === "200") {
        toast.success(res.data.message);
      
      }
      else {
        toast.error(res.data.message);
      }
      this.showQuestion(this.state.selectedTest)
    })
  }
  setOrderBy = (e) => {
    this.listCategoryorderBy(e.target.value)
   }
   listCategoryorderBy(orderBy){
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10,
      "orderBy":orderBy
    }
    axios.post(Api.API_URL +'module-wise-list-category',pagination)
    .then(response => {
  
      this.setState({categories: response.data.result })
    })
    .catch(error => {
      toast.error(error)
    })
  }
  render() {
    return (
      <React.Fragment>
        <h5 className="text-muted pl-5 pt-4 text-right">
          <Link to="questions" className="dropdown-item"><span className="material-icons" >add</span>Add Questions</Link>
        </h5>
        <div className="form-group">
          <div className="container-fluid pt-3 pb-5 mb-5">
            <div className="row">
              <div className="col-md-3 pb-3">

                <div className="card">
                  <div className="card-body">
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                    <label>Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.category}
                        </option>
                      ))}
                    </select><br></br>

                    <label>Select Sub-category</label>
                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select><br></br>

                    <label>Select Test</label>
                    <select
                      value={this.state.selectedTest}
                      onChange={this.setTestId}
                      className="form-control">
                      {this.state.data.map(test => (
                        <option
                          key={test.value}
                          value={test.value}
                        >
                          {test.display}

                        </option>
                      ))}
                    </select><br></br><hr></hr>

                    <button type="button" className="btn primary ml-3" onClick={this.setDisplay}>Show Questions</button>

                  </div>
                </div>
              </div>

              <div className="col-md-9 ">
                {/* <div className="card example-1 scrollbar-ripe-malinka pt-4 pl-4 pr-4">
                  <div className="card-body">
                    {this.state.display && <QuestionList questions={this.state.questionList} />}

                    { !this.state.display &&
                    this.state.questionList.map((question, index) => (
                      <p key={index}><b>{question.questionId}.    {question.question}</b></p>

                    )
                    )
                                      }
                  </div>
                </div> */}
                 <MaterialTable
          title="All questions"
          columns={this.state.columns}
          data={this.state.questionList}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve) => {
            //     // console.log("From onRowAdd")
            //     setTimeout(() => {
            //       resolve();
            //       this.setState((prevState) => {
            //         const add = [...prevState.add];
            //         add.push(newData);
            //         {this.addCategoryData(newData)}
            //         return { ...prevState, add };
            //       });
            //       // { this.addCategoryData(newData) }
            //     }, 600);
            //   }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve) => {
            //     // console.log("Resolve: ", resolve);
            //     setTimeout(() => {
            //       resolve();
            //       if (oldData) {
            //         // console.log("oldData: ", oldData);
            //         // console.log("newData: ", newData);
            //         { this.updateQuestion(newData) }
            //         this.setState((prevState) => {
            //           // console.log("prevState: ", prevState);
            //           const data = [...prevState.data];
            //           data[data.indexOf(oldData)] = newData;
            //           return { ...prevState, data };
            //         });
            //       }
            //     }, 600);
            //   }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  this.setState((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });

                { this.deleteTest(oldData) }
                }, 600);
              }),
          }}
        />
              </div>
            </div>
          </div>
          <div className="ml-4 mr-2 mb-5">
          {/* <MaterialTable
          title="All questions"
          columns={this.state.columns}
          data={this.state.questionList}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve) => {
            //     // console.log("From onRowAdd")
            //     setTimeout(() => {
            //       resolve();
            //       this.setState((prevState) => {
            //         const add = [...prevState.add];
            //         add.push(newData);
            //         {this.addCategoryData(newData)}
            //         return { ...prevState, add };
            //       });
            //       // { this.addCategoryData(newData) }
            //     }, 600);
            //   }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve) => {
            //     // console.log("Resolve: ", resolve);
            //     setTimeout(() => {
            //       resolve();
            //       if (oldData) {
            //         // console.log("oldData: ", oldData);
            //         // console.log("newData: ", newData);
            //         { this.updateCategoryList(newData) }
            //         this.setState((prevState) => {
            //           // console.log("prevState: ", prevState);
            //           const data = [...prevState.data];
            //           data[data.indexOf(oldData)] = newData;
            //           return { ...prevState, data };
            //         });
            //       }
            //     }, 600);
            //   }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  this.setState((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });

                { this.deleteTest(oldData) }
                }, 600);
              }),
          }}
        /> */}
        </div>
        </div>



        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </React.Fragment>
    )
  }
}
