import React, { Component } from 'react'
import axios from 'axios';
import {Link} from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import Api from '../Constant/Api';
import Loader from 'react-loader-spinner';


export default class AddInstitute extends Component {
  constructor(props) {
    super(props)

    this.state = {
      instituteType: '',
      selecteInstitute: '',
      allInstitutes: [],
      institute: '',
      location: '',
      columns: [
        { title: <h5><b>Institute</b></h5>, field: 'institute', initialEditValue: '' },
        { title: '', field: '', initialEditValue: '' },
        { title: <h5><b>Location</b></h5>, field: 'location', initialEditValue: '' },

      ],
      data: [],
      selectedInstitute: '',
      showList: '',
      saveList: [],
      fields: {},
      errors: {},
      showInstitute: '',
      loader:''
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    if (this.state.institute === '') {
      formIsValid = false;
      errors["institute"] = "*Please add Institute";
    }
    if (this.state.location === '') {
      formIsValid = false;
      errors["location"] = "*Please add Location";
    }
    if (this.state.showInstitute === '') {
      formIsValid = false;
      errors["showInstitute"] = "*Please Select Institute";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

instituteList(){
  axios.get(Api.API_URL + 'list-institute-type')
  .then(res => {
    if (res.data.status === "200") {
      console.log("result array: ", res.data.result);
      let institutesFromApi = res.data.result.map(inst => {
        return { value: inst.instituteTypeId, display: inst.instituteType };
      });
      this.setState({
        allInstitutes: [
          {
            value: "",
            display: "Select Institute"
          }
        ].concat(institutesFromApi)
      });
    }
    else {
      toast.error(res.data.message);
    }
  })
  .catch(error => {
    toast.error(error);
  });
}
  componentDidMount() {
   this.instituteList()
  }

  setInstitute = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  addinstitute = (e) => {
    this.setState({loader:true})

    console.log("addinstitute", this.state.instituteType)
    var passData = {
      "instituteType": this.state.instituteType
    }
    axios.post(Api.API_URL + 'add-institute-type', passData)
      .then(res => {
        console.log("result: ", res);
        if (res.data.status === "200"){

          toast.success(res.data.message);
          this.setState({loader:false})

          this.instituteList()
        }
        else{
          this.setState({loader:false})
          toast.error(res.data.message);
        }
      })
  }


  setInstituteType = (e) => {
    this.setState({
      selectedInstitute: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select a institute"
          : ""
    })
    console.log("selected institute:", e.target.value)

  }

  add = (e) => {
    e.preventDefault();
    this.setState({loader:true})
    if (this.handleValidation()) {
      var addData = {
        "instituteTypeId": this.state.showInstitute,
        "institute": this.state.institute,
        "location": this.state.location
      }
      axios.post(Api.API_URL + 'add-institute', addData)
        .then(res => {
          if (res.data.status === "200"){
            this.setState({loader:false})
            toast.success(res.data.message);
          }
          else{
            this.setState({loader:false})
            toast.error(res.data.message);
          }
        })
      this.displayInstitute()
    }
    else {
      toast.error("all fields are mandatory")
    }
  }

  displayInstitute = () => {
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10
    }
    var url = Api.API_URL + 'type-wise-institute/' + this.state.selectedInstitute;
    axios.post(url, pagination)
      .then(res => {
        console.log(res)
      })
  }

  showInstituteInTable = (selectedInstitute) => {
    var pagination = {
      "pageNum": 1,
      "numPerPage": 10
    }
    console.log("Inst: ", selectedInstitute);
    var url = Api.API_URL + 'type-wise-institute/' + selectedInstitute;
    axios.post(url, pagination)
      .then(res => {

        const data = res.data.result;
        console.log("From Show:", data);
        let selectedDocs = [];
        res.data.result.map(doc => {
          selectedDocs = selectedDocs.concat(doc);
        })
        console.log("selected Docs: ", selectedDocs)
        this.setState({
          data: selectedDocs
        })
      })
  }
  setShowInstitute = (e) => {
    this.setState({
      showInstitute: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must Institute"
          : ""
    })
    console.log("show ", e.target.value)
  }

  setshowList = (e) => {
    this.setState({
      showList: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select a institute"
          : ""
    })
    console.log("selected institute:", e.target.value);
    this.showInstituteInTable(e.target.value);


  }

  setAddInstitute = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    if(this.state.loader ===true){
      return <div className="text-center  marginloader" ><Loader
      type="Puff"
      color="#00e600"
      height={100}
      width={100}
      
     //  timeout={3000} //3 secs

   /></div> 
    }
    return (
      <React.Fragment>
           <h5 className="text-muted pl-5 pt-4 text-right">
                <Link to="/listinstitute" className="dropdown-item"><span className="material-icons" >add</span> Institute List</Link>
              </h5>
        <h5 className="text-muted pl-5 pt-4">Add Institute Type</h5>
        <div className="container-fluid ">

          <div className="row">
            <div className="col-md-6 pb-3">
              <div className="card">
                <div className="card-body">
                  <label className="text-muted">Institute Type</label>
                  <input type="text" className="form-control" placeholder="Instituten Type" name="instituteType" onChange={this.setInstitute} /><br></br>

                  <button type="button" className="btn primary" onClick={this.addinstitute}>Add Institute Type</button><br></br><br></br>

                </div>
              </div>
            </div>

            <div className="col-md-6 pb-5 mb-5 ">

              <div className="card">
                <div className="card-body">
                  <label className="text-muted">Institute type List
              </label>
                  <select
                    value={this.state.showInstitute}
                    onChange={this.setShowInstitute}
                    className="form-control">
                    {this.state.allInstitutes.map(inst => (
                      <option
                        key={inst.value}
                        value={inst.value}
                      >
                        {inst.display}

                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["showInstitute"]}</span><br></br>

                  <label className="text-muted">Add Institute</label>
                  <input type="text" className="form-control" placeholder="add institute" name="institute"
                    onChange={this.setAddInstitute}
                  />
                  <span style={{ color: "red" }}>{this.state.errors["institute"]}</span><br></br>

                  <label className="text-muted">Add Location</label>
                  <input type="text" className="form-control" placeholder="add location" name="location"
                    onChange={this.setAddInstitute}
                  />
                  <span style={{ color: "red" }}>{this.state.errors["location"]}</span><br></br>

                  <button type="button" className="btn primary" onClick={this.add} >Add Institute</button><br></br>

                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </React.Fragment>
    )
  }
}



