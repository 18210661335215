import React, { Component } from 'react';
import Api from '../Constant/Api';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';
import {Link} from "react-router-dom";

export default class ListInstitute extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showInstitute: '',
            columns: [
                { title: <h5><b>Institute</b></h5>, field: 'institute', initialEditValue: '' },
                { title: '', field: '', initialEditValue: '' },
                { title: <h5><b>Location</b></h5>, field: 'location', initialEditValue: '' },

            ],
            data: [],
            // showlist:[],
            selectedInstitute: '',
            showList: '',
            saveList: [],
            allInstitutes: [],
        }
    }
    componentDidMount() {
        this.listInstitute()
        this.instituteList()
    }

    instituteList = () => {
        var pagination = {
            "pageNum":1,
            "numPerPage":10
        }
        axios.post(Api.API_URL + 'institute-list',pagination)
        .then(res=>{
            this.setState({
                data: res.data.result
            })
            console.log(res)
        })
    }

    listInstitute = () => {
        axios.get(Api.API_URL + 'list-institute-type')
        .then(res => {
            if (res.data.status === "200") {
                console.log("result array: ", res.data.result);
                let institutesFromApi = res.data.result.map(inst => {
                    return { value: inst.instituteTypeId, display: inst.instituteType };
                });
                this.setState({
                    allInstitutes: [
                        {
                            value: "",
                            display: "Select Institute"
                        }
                    ].concat(institutesFromApi)
                });
            }
            else {
                toast.error(res.data.message);
            }
        })
        .catch(error => {
            toast.error(error);
        });

    }

    setInstitute = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    displayInstitute = () => {
        var pagination = {
            "pageNum": 1,
            "numPerPage": 10
        }
        var url = Api.API_URL + 'type-wise-institute/' + this.state.selectedInstitute;
        axios.post(url, pagination)
            .then(res => {
                console.log(res)
            })
    }
    setInstituteType = (e) => {
        this.setState({
            selectedInstitute: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must select a institute"
                    : ""
        })
        console.log("selected institute:", e.target.value)

    }

    showInstituteInTable = (selectedInstitute) => {
        var pagination = {
            "pageNum": 1,
            "numPerPage": 10
        }
        console.log("Inst: ", selectedInstitute);
        var url = Api.API_URL + 'type-wise-institute/' + selectedInstitute;
        axios.post(url, pagination)
            .then(res => {

                const data = res.data.result;
                console.log("From Show:", data);
                let selectedDocs = [];
                res.data.result.map(doc => {
                    selectedDocs = selectedDocs.concat(doc);
                })
                console.log("selected Docs: ", selectedDocs)
                this.setState({
                    data: selectedDocs
                })
            })
    }
    setShowInstitute = (e) => {
        this.setState({
            showInstitute: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must Institute"
                    : ""
        })
        console.log("show ", e.target.value)
    }

    setshowList = (e) => {
        this.setState({
            showList: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must select a institute"
                    : ""
        })
        console.log("selected institute:", e.target.value);
        this.showInstituteInTable(e.target.value);


    }

    deleteInstitute = (oldData) =>{
        console.log("oldData",oldData.instituteId)
        axios.delete(Api.API_URL + 'delete-institute/' + oldData.instituteId)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error(res.data.message)
                }

            })
            .catch(error => {
                toast.error(error)
            })
    }

    render() {
        return (
            <React.Fragment>
              <h5 className="text-muted pl-5 pt-4 text-right">
                <Link to="/addinstitute" className="dropdown-item"><span className="material-icons" >add</span>Add Institutes</Link>
              </h5>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="text-muted">Choose Institute Type</h5>
                                    <select
                                        value={this.state.showList}
                                        onChange={this.setshowList}
                                        className="form-control">
                                        {this.state.allInstitutes.map(save => (
                                            <option
                                                key={save.value}
                                                value={save.value}
                                            >
                                                {save.display}

                                            </option>
                                        ))}
                                    </select><br></br>
                                </div>
                            </div>

                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 pb-5 mb-5 pt-2">
                                    <MaterialTable
                                        title="List Institute"
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        editable={{
                                            // onRowAdd: (newData) =>
                                            //   new Promise((resolve) => {
                                            //     // console.log("From onRowAdd")
                                            //     setTimeout(() => {
                                            //       resolve();
                                            //       this.setState((prevState) => {
                                            //         const add = [...prevState.add];
                                            //         add.push(newData);
                                            //         {this.addCategoryData(newData)}
                                            //         return { ...prevState, add };
                                            //       });
                                            //       // { this.addCategoryData(newData) }
                                            //     }, 600);
                                            //   }),
                                            // onRowUpdate: (newData, oldData) =>
                                            //   new Promise((resolve) => {
                                            //     // console.log("Resolve: ", resolve);
                                            //     setTimeout(() => {
                                            //       resolve();
                                            //       if (oldData) {
                                            //         // console.log("oldData: ", oldData);
                                            //         // console.log("newData: ", newData);
                                            //         { this.updateCategoryList(newData) }
                                            //         this.setState((prevState) => {
                                            //           // console.log("prevState: ", prevState);
                                            //           const data = [...prevState.data];
                                            //           data[data.indexOf(oldData)] = newData;
                                            //           return { ...prevState, data };
                                            //         });
                                            //       }
                                            //     }, 600);
                                            //   }),
                                            onRowDelete: (oldData) =>
                                              new Promise((resolve) => {
                                                setTimeout(() => {
                                                  resolve();
                                                  this.setState((prevState) => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                  });

                                                { this.deleteInstitute(oldData) }
                                                }, 600);
                                              }),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
