import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import dateFormat from 'dateformat';
import Api from '../Constant/Api';
import MaterialTable from 'material-table';
import Loader from 'react-loader-spinner';


export default class AddJobs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: new Date(),
            lastDate:new Date(),
            postName: "",
            totalPost:"",
            postDescription:"",
            linkUrl:"",
            moduleType:"",
            documentName: "",
            encodedDocument: "",
            loader:'',
            fields: {},
            errors: {},
            columns: [
                { title: <h5><b>Post Name</b></h5>, field: 'postName', initialEditValue: '' },
                { title: <h5><b>Post Description</b></h5>, field: 'postDescription', initialEditValue: '' },
                { title: <h5><b>Post</b></h5>, field: 'totalPost', initialEditValue: '' },
                { title: <h5><b>Last Date</b></h5>, field: 'lastDate', initialEditValue: '' },

                { title: <h5><b>Link Url</b></h5>, field: 'linkUrl', initialEditValue: '' },
                { title: <h5><b>Documents</b></h5>, field: 'pdfUrl', initialEditValue: '' },

            ],
            data: [],
        }
    }
    downloadFile(e) {

        window.location.href = Api.PDF_PATH + e.target.value+"/"
      }

    componentDidMount() {
        var pagination = {
            pageNum: 1,
            numPerPage: 10,

        }
        var url = Api.API_URL + 'jobs-list';
        axios.post(url, pagination)
            .then(res => {
                for(var i=0; i<res.data.result.length; i++){
                    var pdfUrl=<button onClick={this.downloadFile} value={res.data.result[i].document}>download</button>
                    res.data.result[i].pdfUrl=pdfUrl;
                }
                this.setState({ data: res.data.result })
            })
            .catch(error => {
                console.log(error)
            })
    }

    setPost = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        if (this.state.postName === '') {
            formIsValid = false;
            errors["postName"] = "*Please Add Postname";
        };
        if (this.state.post === '') {
            formIsValid = false;
            errors["post"] = "*Please Add Post";
        };
        if (this.state.documentName === '') {
            formIsValid = false;
            errors["documentName"] = "*Please Add DocumentName";
        };
        if (this.state.startDate1 === '') {
            formIsValid = false;
            errors["startDate1"] = "*Please Add Date";
        };
        this.setState({ errors: errors });
        return formIsValid;
    };
    // handleChange(field, e) {
    //     let fields = this.state.fields;
    //     fields[field] = e.target.value;
    //     this.setState({ fields });
    // };
    handleDate = (date) => {
        this.setState({
            startDate1: dateFormat(date, "yyyy-mm-dd"),
            lastDate: date
        });

    };

    addJobs = (e) => {
        e.preventDefault();
        this.setState({loader:true})
        if (this.handleValidation()) {
            var passdata = {
                "postName": this.state.postName,
                "moduleType":this.state.moduleType,
                "totalPost": this.state.totalPost,
                "linkUrl":this.state.linkUrl,
                "postDescription":this.state.postDescription,
                "document": this.state.documentName,
                "encodedDocument": this.state.encodedDocument,
                "lastDate": this.state.lastDate
            };
            axios.post(Api.API_URL + 'add-jobs', passdata)
                .then(res => {
                    if (res.data.status === "200") {
                        toast.success(res.data.message);
                        this.setState({loader:false})
                        this.componentDidMount()
                    }
                    else {
                        toast.error(res.data.message);
                        this.setState({loader:false})

                    }
                })
        }
        else {
            toast.error("all fields are mandatory ")
            this.setState({loader:false})

        }
    };
    onChange(e) {
        let files = e.target.files;
        let reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = (f) => {
            var str = f.target.result;
            var array = str.split(",", 2)
            this.setState({
                documentName: files[0].name,
                encodedDocument: array[1]
            })
        };
    }

    deleteJob = (oldData) => {
        console.log("oldData", oldData.jobsId)
        axios.delete(Api.API_URL + 'delete-jobs/' + oldData.jobsId)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error(res.data.message)
                }

            })
            .catch(error => {
                toast.error(error)
            })

    }
    setposttype = (e) => {
        this.setState({
          moduleType: e.target.value,
        })
      }

    render() {
        if(this.state.loader ===true){
            return <div className="text-center  marginloader" ><Loader
            type="Puff"
            color="#00e600"
            height={100}
            width={100}

           //  timeout={3000} //3 secs

         /></div>
          }
        return (
            <React.Fragment>
                <h5 className="text-muted pl-5 pt-4">Add Job</h5>
                <div className="container-fluid pb-5 mb-5">
                    <div className="row">
                        <div className="col-md-4 pb-3">
                            <div className="card">
                                <div className="card-body">
                                <label>Select Type</label>
                                    <select className="form-control"
                                     onChange={this.setposttype}>
                                        <option >Selet Type</option>
                                        <option value="job">Job </option>
                                        <option value="posts">Post</option>
                                    </select>
                                    <br></br>
                                    <label>Post Name</label>
                                    <input type="text" className="form-control"
                                        name="postName"
                                        onChange={this.setPost}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["postName"]}</span><br></br>

                                    <label>Post Description</label>
                                    <textarea type="text" className="form-control"
                                        name="postDescription"
                                        onChange={this.setPost}
                                    /><br></br>
                                      <label>Post link url</label>
                                    <input
                                     type="text"
                                      className="form-control"
                                      name="linkUrl"
                                      onChange={this.setPost}
                                    /><br></br>
                                      { //Check if message failed
        (this.state.moduleType === 'job')
          ? <div><label>No. of Post</label>
          <input type="number"
          min="0"
          className="form-control"
          name="totalPost"
              onChange={this.setPost}
          />
          <span style={{ color: "red" }}>{this.state.errors["post"]}</span><br></br>

          <label>Last date to apply</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Choose Date"
                                                format="MM/dd/yyyy"
                                                name="lastDate"
                                                value={this.state.lastDate}
                                                onChange={this.handleDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>

                                    <span style={{ color: "red" }}>{this.state.errors["startDate1"]}</span><br></br><br></br>
                                   </div>
          : <div>   </div>
      }
                         <label>Upload Document</label><br></br>
                                    <input type="file" name="file" onChange={(e) => this.onChange(e)} /><br></br>
                                    <span style={{ color: "red" }}>{this.state.errors["documentName"]}</span><br></br><hr></hr>
                                    <button type="submit" className=" btn primary mb-3 mt-3" onClick={this.addJobs}>Add Job</button>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 pb-5 mb-5">
                        <label>Select Type</label>
                                    <select className="form-control" onChange={this.selecttype}>
                                        <option >Selet Type</option>
                                        <option value="job-list" >Job </option>
                                        <option value="posts-list" >Post</option>
                                    </select>
                                    <br></br>
                            <MaterialTable
                                title="Show Jobs"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowAdd: (newData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("From onRowAdd")
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const add = [...prevState.add];
                                    //         add.push(newData);
                                    //         {this.addCategoryData(newData)}
                                    //         return { ...prevState, add };
                                    //       });
                                    //       // { this.addCategoryData(newData) }
                                    //     }, 600);
                                    //   }),
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("Resolve: ", resolve);
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         // console.log("oldData: ", oldData);
                                    //         // console.log("newData: ", newData);
                                    //         { this.updateCategoryList(newData) }
                                    //         this.setState((prevState) => {
                                    //           // console.log("prevState: ", prevState);
                                    //           const data = [...prevState.data];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState((prevState) => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });

                                                { this.deleteJob(oldData) }
                                            }, 600);
                                        }),
                                }}
                            />

                        </div>
                        {/* <div className="col-md-6">
                        </div>
                        <div className="col-md-6 pt-3">
                        </div>
                        <div className="col-md-6">
                            </div> */}
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        )
    }
}
