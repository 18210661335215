import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';
import Api_url from '../components/Constant/Api';
import Loader from 'react-loader-spinner';

import { Table, Pagination } from 'reactstrap';

export default class Package extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            packageName:'',
            price:'',
            validity:'',
            packageType:'',
            loader:'',
            
            errors: {},
            fields: {},
            columns: [
                // {title:'Image',field: <div><img src="assets/preview.png"/></div>, initialEditValue:<div><img src="assets/preview.png"/></div> },
                { title: '', field: '', initialEditValue: '' },
                { title: <h5><b>Package Name</b></h5>, field: 'packageName' },
                { title: <h5><b>Price</b></h5>, field: 'price' },
                { title: <h5><b>Package type</b></h5>, field: 'packageType' },
                { title: <h5><b>Validity</b></h5>, field: 'validity', initialEditValue: '' },

            ],
            data: [],
            allImages: [],
        }
    }



    AddPackage = (e) => {
        e.preventDefault();
this.setState({loader:true})
        // if (this.handleValidation()) {
            var formData={
                "packageName":this.state.packageName,
               "price":this.state.price,
                 "validity":this.state.validity,
                  "packageType":this.state.packageType,
    
                      }  
            const url = Api_url.API_URL + "add-package";
            return axios.post(url, formData)
                .then(res => {
                    if (res.data.status === "200"){
                        toast.success("Upload successfully!");
                        this.setState({loader:false})

                    
                    } 
                    else{
                        this.setState({loader:false})

                        toast.error(res.data.message);
                    }
                })
        }
        // else {
        //     toast.error("all fields are mandatory")
        // }
    

    Deletepackage = (oldData) => {
           axios.delete(Api_url.API_URL + "delete-package/"+ oldData.packageId,)
            .then(res => {
                if(res.data.status === "200"){
                    toast.success(res.data.message)
                }else{
                    toast.error(res.data.message)
                }
            })
    }
    setModuletype = (e) => {
        this.setState({
          packageType:e.target.value
        })
      }
      setPackagedata = (e) => {
        this.setState({
         [e.target.name]: e.target.value
        })
      }
    
      callpackagelist=(e)=>{
          this.packagelist(e.target.value)
      }
packagelist = (moduleType) => {
    var pagination ={
        "pageNum":1,
    "numPerPage":10,
    "orderBy":moduleType
    }
        axios.post(Api_url.API_URL + "package-list",pagination)
         .then(res => {
             if(res.data.status === "200"){
             this.setState({data:res.data.result})
            }else{
                 toast.error(res.data.message)
             }
         })
 }

    render() {
        if(this.state.loader ===true){
            return <div className="text-center  marginloader" ><Loader
            type="Puff"
            color="#00e600"
            height={100}
            width={100}
            
           //  timeout={3000} //3 secs
      
         /></div> 
          }
        return (
            <React.Fragment>
                <div className="container-fluid pt-3 ">
                    <div className="row">
                        <div className="col-md-4 pb-3">
                            <div className="card">
                                <div className="card-body">
                                <label>Select Package-type</label><br></br>
                  <select className="form-control" onChange={this.setModuletype}  >
                  <option>Select Package-type</option>   
              <option value="ibps-afo">IBPS-AFO</option>    
              <option value="other">Other</option>   
                  </select><br></br>
                  <label>Package Name</label>
              <input type="text" name="packageName" placeholder="Enter packageName" className="form-control "
              onChange={this.setPackagedata}
              /><br></br>
                    <label>Package Price</label>
              <input type="number" min="0" name="price" placeholder="Enter package price" className="form-control "
              onChange={this.setPackagedata}
              /><br></br>
                    <label>Package Validity</label>
              <input type="number" min="0" name="validity" placeholder="Enter package validity" className="form-control "
              onChange={this.setPackagedata}
              /><br></br>
                                 
                                    <button type="button" className=" btn primary mb-3 mt-3" onClick={this.AddPackage}>Add Package</button><br></br>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 pb-5 mb-5">
                        <label>Select Package-type</label><br></br>
                  <select className="form-control" onChange={this.callpackagelist}  >
                  <option>Select Package-type</option>   
              <option value="IBPS_AFO">IBPS-AFO</option>    
              <option value="other">Other</option>   
                  </select><br></br>
                            <MaterialTable
                                title="List Package"
                                columns={this.state.columns}
                                data={this.state.data}
                             
                                editable={{
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState((prevState) => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });

                                                { this.Deletepackage(oldData) }
                                            }, 600);
                                        }),
                                }}
                            />

                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />

            </React.Fragment>
        )
    }
}
