import React, { Component } from 'react'
import Api from '../Constant/Api';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
export default class UserDetails extends Component {
  constructor(props) {
    super(props)
    this.loginUser = JSON.parse(localStorage.getItem('agriguruUser'));
    this.state = {
      uniqueNo:this.loginUser.uniqueNo,
      password:'',
      newPassword:'',
      firstName:'',
      lastName:'',
      mobileNo:'',
      email:''

    }
    this.Submitchangeedpassword=this.Submitchangeedpassword.bind(this);
    this.updateProfile=this.updateProfile.bind(this)
  }
    changeHandler = (e) => {
      this.setState({ [e.target.name]: e.target.value })
    }
    Submitchangeedpassword(){   
      toast.configure();
      
      var passworddata = {
        uniqueNo:this.state.uniqueNo,
        newPassword: this.state.newPassword,
        password: this.state.password
      }; 
      axios.post(Api.API_URL+'change-user-password', passworddata)
        .then(res => {
          if (res.data.status === "200") {
            toast.success("Password Changed Successfully");    
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    updateProfile(){   
      toast.configure();
      
      var profiledata = {
        adminId:this.loginUser.adminId,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        mobileNumber:this.state.mobileNumber
      }; 
      axios.post(Api.API_URL+'update-admin', profiledata)
        .then(res => {
          if (res.data.status === "200") {
            toast.success("Profile Updated  Successfully");   
            localStorage.setItem('agriguruUser', JSON.stringify(res.data.result)); 
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  render() {

    return (
      <div>
        <div className="row">
          <div className="col-6"><div className="card">
                  <div className="card-body">
                    <label>First Name</label>
                   <input className="form-control" className="form-control" type="text" name="firstName" defaultValue={this.loginUser.firstName} onChange={this.changeHandler}></input><br></br>

                    <label>Last Name</label>
                    <input className="form-control" className="form-control" type="text" name="lastName" defaultValue={this.loginUser.lastName} onChange={this.changeHandler}></input><br></br>

                    <label>Mobile no</label>
                    <input min="0" max="10" className="form-control" type="number" name="mobileNumber" defaultValue={this.loginUser.mobileNumber} onChange={this.changeHandler}></input><br></br>

                    <label>Email</label>
                    <input className="form-control" type="text" name="email" defaultValue={this.loginUser.email} onChange={this.changeHandler}></input><br></br><hr></hr>

                    <button type="button" className="btn primary ml-3"  onClick={this.updateProfile}>Submit</button>

                  </div>
                </div></div>
                <div className="col-6"><div className="card">
                  <div className="card-body">
                    
                    <label>Current password</label>
                   <input type="password" className="form-control" name="password" onChange={this.changeHandler}></input><br></br>

                   <label>New password</label>
                   <input className="form-control" type="password" name="newPassword"  onChange={this.changeHandler}></input><br></br>

                   <label>Repeat new password</label>
                   <input className="form-control" type="password"  onChange={this.changeHandler}></input><br></br>

               <hr></hr>

                    <button type="button" className="btn primary ml-3"  onClick={this.Submitchangeedpassword}>Submit</button>

                  </div>
                </div></div>
        </div>
        
      </div>
    )
  }
}

