import React, { Component } from 'react'
export default class QuestionList extends Component {

    render() {
        const question = this.props.questions;

        return question.map((question, index) => {
            return (
                <div>
                    <table>
                        <tr>
                            <th><hr></hr>{index + 1}.{question.question}</th>
                        </tr>
                    </table>

                    {question.objective.map((objective, key) => {
                        return (
                            <div>
                                <label for={objective.objective}>{key + 1}. &nbsp;{objective.objective}</label>
                            </div>
                        )

                    })}</div>
            )
        })

    }
}
