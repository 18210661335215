import React, { Component } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import Api from '../Constant/Api'
import Api_url from '../Constant/Api'
import { ToastContainer, toast } from "react-toastify";
import Loader from 'react-loader-spinner';



export default class Videoseries extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
        title:'',
        description:'',
        validity:'',
        price:'',
        videoGrpList:[],
        categories: [],
        selectedCategory: "",
        selectedSubCategory: "",
        subCat: [],
        loader:'',
      columns: [
        { title: <h5><b>title</b></h5>, field: 'title', initialEditValue: '' },
        { title: <h5><b>description</b></h5>, field: 'description', initialEditValue: '' },
        { title: <h5><b>validity</b></h5>, field: 'validity', initialEditValue: '' },
        { title: <h5><b>price</b></h5>, field: 'price', initialEditValue: '' },

        
      ],
      data: [],
      videolist:[]

       
    }
  } 
  componentDidMount(){
    // this.listCategory()
  }

 
  Videolist(selectedSubCategory) {
    axios.post(Api.API_URL+'subcategory-wise-video?subcategoryId='+selectedSubCategory,{  "pageNum":1,"numPerPage":100 })
      .then(response => {
        this.setState({ videolist: response.data.result })
      })
      .catch(error => {
        toast.error(error)
      })
  }



  VideoSerieslist(selectedSubCategory) {
    axios.post(Api.API_URL +'subcategory-wise-video-series?subcategoryId='+selectedSubCategory,{  "pageNum":1,"numPerPage":100, })
      .then(response => {

        this.setState({ data: response.data.result })
      })
      .catch(error => {
        toast.error(error)
      })
  }

   onChangevideoseries = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  AddvideoSeries= (e) =>  {
    toast.configure();

    this.setState({loader:true})
    e.preventDefault();
   var videodata={
      "title":this.state.title,
      "validity":this.state.validity,
      "description":this.state.description,
      "price":this.state.price,
      "videoGrpList":this.state.videoGrpList,
      "subcategoryId":this.state.selectedSubCategory
     
    }
    axios.post(Api_url.API_URL +'add-video-series-subcat-wise',videodata)
      .then(response => {
        if(response.data.status==="200"){
          this.setState({loader:false})
          toast.success(response.data.message);
        }else{
          this.setState({loader:false})
          toast.error(response.data.message);
        }
      })
      .catch(error => {
        toast.error(error)
      })
  }
  listCategory = () => {
    axios.get(Api.API_URL + 'list-category')
      .then(res => {
        if (res.data.status === "200") {
          let categoriesFromApi = res.data.result.map(cat => {
            return { value: cat.categoryId, display: cat.category };
          });
          this.setState({
            categories: [
              {
                value: "",
                display: "Select Category"
              }
            ].concat(categoriesFromApi)
          });
        }
        else {
          toast.error(res.data.message);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  }

  setCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite category"
          : ""
    })
    this.fetchSubcategory(e.target.value);
  }


  updateVideoSeries(newData) {
   
    var params = {
      "categoryId": newData.categoryId,
      "category": newData.category
    }
    axios.post(Api_url.API_URL + 'update-category', params)
      .then(res => {
        toast.success(res.data.message);
      })
      .catch(error => {
        toast.error(error)
      })
  }
  DeletevideoseriesList(oldData) {
    const url = Api_url.API_URL + 'delete-video-series/' + oldData.videoSeriesId;
    axios.delete(url)
      .then(res => {
        toast.success(res.data.message);
      })
      .catch(error => {

        toast.error(error)
      })

  }
  fetchSubcategory(selectedCategory) {
    if (selectedCategory !== '') {
      var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
      axios.get(url)
        .then(res => {
          if (res.data.status === "200") {
            let subCategoriesFromApi = res.data.result.map(cat => {
              return { value: cat.subcategoryId, display: cat.subcatName };
            });
            this.setState({
              subCat: [
                {
                  value: "",
                  display: "Select Sub-Category"
                }
              ].concat(subCategoriesFromApi)
            });
          }
          else {
            toast.error(res.data.message);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }


  setSubCategory = (e) => {
    this.setState({
      selectedSubCategory: e.target.value,
      validationError:
        e.target.value === ""
          ? "You must select your favourite sub category"
          : ""
    })
    this.Videolist(e.target.value)
    this.VideoSerieslist(e.target.value)
  }
  onChangeselectvideo = (e,i) => {
    const video={'testId':e.target.value}
    this.state.videoGrpList.push(video);
    }
    setOrderBy = (e) => {
      this.listCategoryorderBy(e.target.value)
     }
     listCategoryorderBy(orderBy){
      var pagination = {
        "pageNum": 1,
        "numPerPage": 10,
        "orderBy":orderBy
      }
      axios.post(Api.API_URL +'module-wise-list-category',pagination)
      .then(response => {
    
        this.setState({categories: response.data.result })
      })
      .catch(error => {
        toast.error(error)
      })
    }
    render() {
      if(this.state.loader ===true){
        return <div className="text-center  marginloader" ><Loader
        type="Puff"
        color="#00e600"
        height={100}
        width={100}
        
       //  timeout={3000} //3 secs
  
     /></div> 
      }
        return (
            <div>
              <div className="row mr-2">
                <div className="col-6">
                <div className="form-group">
              <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-md-12 ">
                <div className="card">
                  <div className="card-body">
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                  <label>Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.category}
                        </option>
                      ))}
                    </select><br></br>

                    <label>Select Sub-category</label>
                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select><br></br>
                  <label>Title</label>
              <input type="text" name="title" placeholder="Enter series number" className="form-control "
              onChange={this.onChangevideoseries}
              /><br></br>
                    <label>Validity</label>
              <input type="number" min="0" name="validity" placeholder="Enter validity-number" className="form-control "
              onChange={this.onChangevideoseries}
              /><br></br>
                       <label>Price</label>
              <input type="number" min="0" name="price" placeholder="Enter validity-number" className="form-control "
              onChange={this.onChangevideoseries}
              /><br></br>
                       <label>Description</label>
              <textarea type="text" name="description" placeholder="Enter validity-number" className="form-control "
              onChange={this.onChangevideoseries}
              /><br></br>
<div className="btn-group">
  <button className="btn form-group dropdown-toggle test-primary border-dark" data-toggle="dropdown">
    select video
  </button>
  
  <ul className="dropdown-menu" style={{padding: '10px'}} id="myDiv" >
  {this.state.videolist.map((item, i) => {
          return (
    <li key={i}><p><input type="checkbox" defaultValue={item.videoId} onChange={this.onChangeselectvideo} /> {item.title} </p></li>
    )
  }, this)}
  </ul>

</div>
         </div>
                </div>
              </div>
            </div>
            <br></br>
            <button type="button" className=" btn primary from-group" onClick={this.AddvideoSeries}>Add</button>
<br></br><br></br><br></br> <br></br>
          </div>
          </div>
          </div>
          <div className="col-6 pt-3">

          <div className="card">
                  <div className="card-body">
                  <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                  <label>Select Category</label>
                    <select
                      value={this.state.selectedCategory}
                      onChange={this.setCategory}
                      className="form-control">
                      {this.state.categories.map(cat => (
                        <option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.category}
                        </option>
                      ))}
                    </select><br></br>


                    <label>Select Sub-category</label>
                    <select
                      value={this.state.selectedSubCategory}
                      onChange={this.setSubCategory}
                      className="form-control">
                      {this.state.subCat.map(cat => (
                        <option
                          key={cat.value}
                          value={cat.value}
                        >
                          {cat.display}

                        </option>
                      ))}
                    </select><br></br>
                    </div>
                    </div>
                    <br></br>
          <MaterialTable
          className="mr-2"
                title="Video series List"
                columns={this.state.columns}
                data={this.state.data}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          { this.updateVideoSeries(newData) }
                          this.setState((prevState) => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        this.setState((prevState) => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                        { this.DeletevideoseriesList(oldData) }
                      }, 600);
                    }),
                }}
              />
          </div>
              </div>

            </div>
        )
    }
}
