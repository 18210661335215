import React, { Component } from 'react';
import Api from '../Constant/Api';
import MaterialTable from 'material-table';
import axios from 'axios';

export default class StudentList extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            columns: [
                { title: <h5><b>Full Name</b></h5>, field: 'fullName', initialEditValue: '' },
                // { title: <h5><b>Last Name</b></h5>, field: 'lastName', initialEditValue: '' },
                { title: <h5><b>Gender</b></h5>, field: 'gender', initialEditValue: '' },
                { title: <h5><b>Mobile</b></h5>, field: 'mobile', initialEditValue: '' },
                { title: <h5><b>Email</b></h5>, field: 'email', initialEditValue: '' },
              ],
              data: [],
        }
    }

    componentDidMount(){
        this.StudentList()
    }

    StudentList = () =>{
        var pagination = {
            "pageNum":1,
            "numPerPage":10
          }
          axios.post(Api.API_URL + 'list-student' ,pagination)
            .then(res => {
             this.setState({
              data:res.data.result
             })
            })
    }
    
    render() {
        return (
            <React.Fragment>
               <div className="container-fluid pt-3">
                   <div className="row">
                       <div className="col-md-12 pb-5 mb-5">
                       <MaterialTable
          title="Student-List "
          columns={this.state.columns}
          data={this.state.data}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve) => {
            //     // console.log("From onRowAdd")
            //     setTimeout(() => {
            //       resolve();
            //       this.setState((prevState) => {
            //         const add = [...prevState.add];
            //         add.push(newData);
            //         {this.addCategoryData(newData)}
            //         return { ...prevState, add };
            //       });
            //       // { this.addCategoryData(newData) }
            //     }, 600);
            //   }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve) => {
            //     // console.log("Resolve: ", resolve);
            //     setTimeout(() => {
            //       resolve();
            //       if (oldData) {
            //         // console.log("oldData: ", oldData);
            //         // console.log("newData: ", newData);
            //         { this.updateCategoryList(newData) }
            //         this.setState((prevState) => {
            //           // console.log("prevState: ", prevState);
            //           const data = [...prevState.data];
            //           data[data.indexOf(oldData)] = newData;
            //           return { ...prevState, data };
            //         });
            //       }
            //     }, 600);
            //   }),
            // onRowDelete: (oldData) =>
            //   new Promise((resolve) => {
            //     setTimeout(() => {
            //       resolve();
            //       this.setState((prevState) => {
            //         const data = [...prevState.data];
            //         data.splice(data.indexOf(oldData), 1);
            //         return { ...prevState, data };
            //       });
                  
            //     { this.DeleteCategoryList(oldData) }
            //     }, 600);
            //   }),
          }}
        />
                       </div>
                   </div>
               </div>
            </React.Fragment>
        )
    }
}
