import React, { Component } from 'react'
import MaterialTable from 'material-table';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import Api from '../components/Constant/Api'

import { Document, Page } from 'react-pdf';
import {Link} from "react-router-dom";


export default class Listnotes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedCategory: "",
            selectedSubCategory: "",
            // selectedSubCategory: "",
            showDocuments: "",
            type: [{ "value": "", "display": "select document type" }, { "value": "notes", "display": "notes" }, { "value": "syllabus", "display": "syllabus" }, { "value": "previousPaper", "display": "previousPaper" }, { "value": "oneLiner", "display": "One-liner" }, { "value": "agriTable", "display": "Agri-Table" }, { "value": "IBPS-AFO", "display": "IBPS-AFO" }],
            orderby: [{ "value": "", "display": "select document type" }, { "value": "notes-pdf", "display": "notes-pdf" }, { "value": "syllabus-pdf", "display": "syllabus-pdf" }, { "value": "previous-paper-pdf", "display": "previous-paper-pdf" }, { "value": "one-liner-pdf", "display": "One-liner" }, { "value": "agri-table-pdf", "display": "Agri-Table" }, { "value": "IBPS-AFO-pdf", "display": "IBPS-AFO" }],
            columns: [
                // { title: '', field: '', initialEditValue: '' },
                { title: <h5><b>Title</b></h5>, field: 'title', initialEditValue: '' },
                { title: <h5><b>Description</b></h5>, field: 'description', initialEditValue: '' },
                { title: <h5><b>Document type</b></h5>, field: 'documentType', initialEditValue: '' },
                { title: <h5><b>Documents</b></h5>, field: 'pdfUrl', initialEditValue: '' },
            ],
            data: [],
            categories: [],
            subCat: [],
            fields: {},
            errors: {},


        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (this.state.selectedCategory === '') {
            formIsValid = false;
            errors["selectedCategory"] = "*Please Select Category";
        }
        if (this.state.selectedSubCategory === '') {
            formIsValid = false;
            errors["selectedSubCategory"] = "*Please Select Sub-Category";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    componentDidMount() {
        this.listCategory()
        this.documentList()
    }
    downloadFile(e) {
        window.location.href = Api.PDF_PATH+e.target.value+"/"
      }
    documentList = () => {
        var pagination = {
            pageNum: 1,
            numPerPage: 50,

        }
        axios.post(Api.API_URL + 'document-list' ,pagination)
          .then(res => {
            for(var i=0; i<res.data.result.length; i++){
                var pdfUrl=<button onClick={this.downloadFile} value={res.data.result[i].document}>download</button>
                res.data.result[i].pdfUrl=pdfUrl;
            }
           this.setState({
            data:res.data.result
           })
          })
      }

    listCategory = () => {
        axios.get(Api.API_URL + 'list-category')
            .then(res => {
                if (res.data.status === "200") {
                    // console.log("result array: ", res.data.result);
                    let categoriesFromApi = res.data.result.map(cat => {
                        return { value: cat.categoryId, display: cat.category };
                    });
                    this.setState({
                        categories: [
                            {
                                value: "",
                                display: "Select Category"
                            }
                        ].concat(categoriesFromApi)
                    });
                }
                else {
                    toast.error(res.data.message);
                }
            })
            .catch(error => {
                toast.error(error);
            });
    }

    setCategory = (e) => {
        this.setState({
            selectedCategory: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must select your favourite category"
                    : ""
        })
        // console.log("setcategory",e.target.value)
        this.fetchSubcategory(e.target.value);
    }
    fetchSubcategory(selectedCategory) {
        // console.log("fetchsub: ", selectedCategory)
        if (selectedCategory !== '') {
            var url = Api.API_URL + 'category-wise-subcategory/' + selectedCategory;
            axios.get(url)
                .then(res => {
                    for(var i=0; i<res.data.result.length; i++){
                        var pdfUrl=<button onClick={this.downloadFile} value={res.data.result[i].document}>download</button>
                        res.data.result[i].pdfUrl=pdfUrl;
                    }
                    if (res.data.status === "200") {
                      
                       this.setState({
                        data:res.data.result
                       })
                        let subCategoriesFromApi = res.data.result.map(cat => {
                            return { value: cat.subcategoryId, display: cat.subcatName };
                        });
                        this.setState({
                            subCat: [
                                {
                                    value: "",
                                    display: "Select Sub-Category"
                                }
                            ].concat(subCategoriesFromApi)
                        });
                    }
                    else {
                        toast.error(res.data.message);
                    }
                })
                .catch(error => {
                    toast.error(error);
                });
        }
    }

    setSubCategory = (e) => {
        this.setState({
            selectedSubCategory: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must select your favourite sub category"
                    : ""
        })
        // console.log("set sub category:",e.target.value)

    }
    setshowDocuments = (e) => {
        this.setState({
            showDocuments: e.target.value,
            validationError:
                e.target.value === ""
                    ? "You must select order by"
                    : ""
        })
        // this.setShowtable();
        this.fetchPdf(e.target.value);
    }

    fetchPdf = (selectedOrderbyValue) => {
        var pagination =
        {
            "pageNum": 1,
            "numPerPage": 10,
            "orderBy": selectedOrderbyValue,

        }

        axios.post(Api.API_URL +'subcategory-wise-notes-list/' + this.state.selectedSubCategory ,pagination)
        .then(res => {
          for(var i=0; i<res.data.result.length; i++){
              var pdfUrl=<button onClick={this.downloadFile} value={res.data.result[i].document}>download</button>
              res.data.result[i].pdfUrl=pdfUrl;
          }
         this.setState({
          data:res.data.result
         })
        })
            .catch(error => {
                toast.error(error);
            });

        // this.updateDocument();

    }
    deleteNotes = (oldData) => {
        console.log("old Data:", oldData);
        // console.log("updatedata",updateData);
        var url = Api.API_URL + 'delete-notes/' + oldData.notesId;
        axios.delete(url)
            .then(res => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error(res.data.message)

                }

            })
    }
    setOrderBy = (e) => {
        this.listCategoryorderBy(e.target.value)
       }
       listCategoryorderBy(orderBy){
        var pagination = {
          "pageNum": 1,
          "numPerPage": 10,
          "orderBy":orderBy
        }
        axios.post(Api.API_URL +'module-wise-list-category',pagination)
        .then(response => {
      
          this.setState({categories: response.data.result })
        })
        .catch(error => {
          toast.error(error)
        })
      }
    render() {
        return (
            <React.Fragment>
              <h5 className="text-muted pl-5 pt-4 text-right">
                <Link to="/notes" className="dropdown-item"><span className="material-icons" >add</span>Add Notes</Link>
              </h5>
                <div className="container-fluid pt-3 pb-5 mb-3">
                    <div className="row">
                        <div className="col-md-3 pb-3">
                            <div className="card">
                                <div className="card-body">
                                <label>Select Module</label><br></br>
                  <select className="form-control" onChange={this.setOrderBy} >
                  <option>Select module-type</option>   
              <option value="IBPS-AFO">IBPS-AFO</option>   
              <option value="Agri-Table">Agri-Table (Statistics)</option>  
              <option value="Videos">Videos</option>   
              <option value="Other">Other</option>   
                  </select><br></br>
                                    <label >Select Category</label>
                                    <select
                                        value={this.state.selectedCategory}
                                        onChange={this.setCategory}
                                        className="form-control">
                                        {this.state.categories.map(cat => (
                                            <option
                                                key={cat.categoryId}
                                                value={cat.categoryId}
                                            >
                                                {cat.category}
                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: "red" }}>{this.state.errors["selectedCategory"]}</span>
                                    <br></br>
                                    <label >Select Sub-category</label>
                                    <select
                                        value={this.state.selectedSubCategory}
                                        onChange={this.setSubCategory}
                                        className="form-control">
                                        {this.state.subCat.map(cat => (
                                            <option
                                                key={cat.value}
                                                value={cat.value}
                                            >
                                                {cat.display}

                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: "red" }}>{this.state.errors["selectedSubCategory"]}</span>
                                    <br></br>


                                    <label>Show List</label>
                                    <select
                                        value={this.state.showDocuments}
                                        onChange={this.setshowDocuments}
                                        className="form-control">
                                        {this.state.orderby.map(docType => (
                                            <option
                                                key={docType.value}
                                                value={docType.value}
                                            >
                                                {docType.display}

                                            </option>
                                        ))}
                                    </select><br></br>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9 pb-4 mb-4">
                            <MaterialTable
                                title="List Doc"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("Resolve: ", resolve);
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         // console.log("oldData: ", oldData);
                                    //         // console.log("newData: ", newData);
                                    //         { this.updateDocument(newData) }
                                    //         console.log("show update", this.data)
                                    //         this.setState((prevState) => {
                                    //           // console.log("prevState: ", prevState);
                                    //           const data = [...prevState.pdfData];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState((prevState) => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                                { this.deleteNotes(oldData) }
                                            }, 600);
                                        }),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </React.Fragment>
        )
    }
}
