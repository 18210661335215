import React, { Component } from 'react'
import axios from 'axios'
import './css/Forgot.css'
import { withRouter } from 'react-router';
import { ToastContainer, toast } from "react-toastify";

class Otp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      otp: '',
      uniqueNo: this.props.match.params.uniqueNo,

    }
  }

  updateOtp = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    console.log(this.state.otp);
  }

  resendOtp = (e) => {
    e.preventDefault();
    console.log(this.state);
    var data = {
      uniqueNo: this.state.uniqueNo
    }
    axios.post('https://agriguru.avertastrategy.com/v1/student/resend-otp', data)
      .then(res => {
        // console.log("response from resendOtp: ",res);
        toast.success(res.data.message)
      })
      .catch(error => {
        // console.log(error)
        toast.error(error)
      })
  }

  verifyOtp = (e) => {
    e.preventDefault()
    // console.log("after clicking submit",this.state);
    var otpData = {
      otp: this.state.otp,
      uniqueNo: this.state.uniqueNo
    }

    // console.log("after clicking submit",this.state);
    axios.post('https://agriguru.avertastrategy.com/v1/student/verify-otp', otpData)
      .then(res => {
        // console.log(res);
        if (res.data.status === "200") {
          toast.success(res.data.message)
          this.props.history.push('/resetpassword/' + this.state.uniqueNo);
        }
        else {
          // alert(res.data.message);
          toast.error(res.data.message)
        }

      })
      .catch(error => {
        // console.log(error)
        toast.error(error)
      })

  }

  render() {

    return (
      <React.Fragment>
        <div className="form-gap" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-md-offset-4">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="text-center">
                    <h3><i className="fa fa-lock fa-4x" /></h3>
                    <h2 className="text-center">Enter OTP</h2>
                    <div className="panel-body">
                      <form id="register-form" role="form" autoComplete="on" className="form" method="post" onSubmit={this.checkOtp}>
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon pr-4"><i className="fas fa-unlock-alt color-blue" /></span>
                            <input id="otp" name="otp" placeholder="Enter OTP Here.." className="form-control" type="password" onChange={this.updateOtp} />
                          </div>
                        </div>
                        <div className="form-group">
                          {/* <input name="recover-submit" className="btn btn-lg btn-primary btn-block" defaultValue="Reset Password" type="submit" /> */}
                          <button className="btn btn-danger ml-5" type="submit" onClick={this.resendOtp}>Resend Otp</button>
                          <button className="btn btn-success ml-3" type="submit" onClick={this.verifyOtp}>Verify Otp</button>

                        </div>
                        <input type="hidden" className="hide" name="token" id="token" defaultValue />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-md-4">
               </div>
               <div className="col-md-4">
               <form className="form-group" >
                <h2 className="ml-5">Enter OTP</h2>
                <input id="otp" name="otp" placeholder="Enter OTP Here.." className="form-control" type="text" onChange={this.updateOtp} />
              </form>
              <button className="btn btn-danger ml-5" type="submit" onClick={this.resendOtp}>Resend Otp</button>
              <button className="btn btn-success ml-3" type="submit" onClick={this.verifyOtp}>Verify Otp</button>
               </div>
               <div className="col-md-4">
               </div>
          </div>
        </div> */}
      <ToastContainer 
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      </React.Fragment>
    )
  }
}
export default withRouter(Otp);

