import React, { Component } from 'react'

export default class Editcategory extends Component {
    constructor(props) {
        super(props)
        console.log("EDIT CATEDG",JSON.stringify(this.props.match.params.data))

        this.state = {
             
        }
    }
    
    render() {
        return (
            <div>
                <h1>jj</h1>
            </div>
        )
    }
}
